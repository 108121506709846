const API_BASE_URL = ' https://7dm2hmhch3.execute-api.us-west-2.amazonaws.com/prod'
const S3_BUCKET_URL = 'https://mdh-927728891088-bucket.s3.us-west-2.amazonaws.com'
const USER_POOL_ID = 'us-west-2_m09hktFQQ'
const USER_POOL_WEB_CLIENT_ID = '3h3l30v8i6fkt1j22tojqgnq0i'
const USER_POOL_REGION = 'us-west-2'

const SUBSCRIPTION_PRODUCT_ID = "prod_NYNr1eiWp3yavd"
const PAYMENT_PRODUCT_ID = "prod_MsaFnWSVH4pJzX"

const DEFAULT_CURRENCY = "USD"

const MODEL_CONFIG = {
    "inputType": "image", // image/video/audio/csv/raw_text/
    "outputType": "image", // image/video/audio/csv/raw_text/
    "modelName": "midas-lambda", // chatgpt-lambda/openai-image
    "inferenceType": "queue", // queue/realtime
}

const APP_THEME = {
    primaryColor: '#000',
}

export {
    API_BASE_URL,
    S3_BUCKET_URL,
    USER_POOL_ID,
    USER_POOL_WEB_CLIENT_ID,
    USER_POOL_REGION,
    SUBSCRIPTION_PRODUCT_ID,
    PAYMENT_PRODUCT_ID,
    MODEL_CONFIG,
    APP_THEME,
    DEFAULT_CURRENCY
}