import { Box, Center, HStack, Heading, Icon, Pressable, SectionList, Text } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { allTools } from 'src/data/tools';
import { LinkButton } from '../common/LinkButton';

export default function ToolCategoryList({ navigation }) {

    const sectionListData = allTools.map((item) => {
        return {
            title: item.categoryDisplayName,
            data: item.tools
        }
    })

    return <Center h="100%" w="100%">
        <SectionList w="95%"
            sections={sectionListData}
            keyExtractor={(item, index) => item.name + index} renderItem={({
                item
            }) => {
                console.log('item', item.category, item.name)
                console.log('navigation', `${item.category}\/${item.name}`)
                return <LinkButton to={{ screen: `${item.category}\/${item.name}` }}>
                    <Box maxW="100%"
                        borderWidth="1"
                        margin={1.5}
                        borderColor="coolGray.300"
                        shadow="3" bg={'coolGray.100'}
                        p="2"
                        rounded="8" style={{
                            transform: [{
                                scale: 1
                            }]
                        }}>
                        <HStack style={{
                            margin: 4,
                        }} space={2}>
                            <Center size={12} bg="primary.300" rounded="md">
                                <Icon as={MaterialCommunityIcons}
                                    name={item.icon}
                                    size="lg" />
                            </Center>
                            <Box width={"100%"}>
                                <Heading fontSize="md">
                                    {item.displayName}
                                </Heading>
                                <Text width={"80%"}>
                                    {item.description}
                                </Text>
                            </Box>
                        </HStack>
                    </Box>
                </LinkButton>
            }}
            renderSectionHeader={({
                section: {
                    title
                }
            }) => <Heading fontSize="lg" mt="8" pb="4">
                    {title}
                </Heading>} />
    </Center>;
};
const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 16,
        color: '#fff'
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    cardStyle: {
        margin: 4,
        backgroundColor: 'gray'
    }
});