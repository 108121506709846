import React from 'react';
import { View, Box, Button, FormControl, Center, KeyboardAvoidingView } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setInvestmentCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IInvestmentCalculatorInput } from 'src/models';
import { IStore } from 'src/redux/reducers';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import PercentInput from 'src/components/common/PercentInput';
import SelectWithLabel from 'src/components/common/SelectWithLabel';
import ValueWithInputTypeToogle from 'src/components/common/ValueWithInputTypeToogle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default function InvestmentCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const calculatorInputs: IInvestmentCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.investmentCalculatorInput);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === calculatorInputs?.currency)?.Symbol || "$";

    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(calculatorInputs?.currency || "USD");

    const [initialAmount, setInitialAmount] = React.useState(`${calculatorInputs?.initialInvestment || 1000}`);
    const [additionalInvestment, setAdditionalInvestment] = React.useState(`${calculatorInputs?.additionalInvestment || 100}`);
    const [investmentPeriod, setInvestmentPeriod] = React.useState(`${calculatorInputs?.investmentPeriod || 10}`);
    const [investmentPeriodType, setInvestmentPeriodType] = React.useState(`${calculatorInputs?.investmentPeriodType || "yrs"}`); // ["yrs", "mths"
    const [returnRate, setReturnRate] = React.useState(`${calculatorInputs?.returnRate || 7}`);
    const [contributionFrequency, setContributionFrequency] = React.useState(`${calculatorInputs?.contributionFrequency || "monthly"}`);
    const [compoundFrequency, setCompoundFrequency] = React.useState(`${calculatorInputs?.compoundFrequency || "monthly"}`);

    const [loading, setLoading] = React.useState(false);


    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setInvestmentCalculatorInput({
            currency: currencyCode,
            initialInvestment: parseFloat(initialAmount) || 0,
            additionalInvestment: parseFloat(additionalInvestment) || 0,
            investmentPeriod: parseFloat(investmentPeriod) || 0,
            investmentPeriodType: investmentPeriodType,
            returnRate: parseFloat(returnRate) || 0,
            contributionFrequency: contributionFrequency,
            compoundFrequency: compoundFrequency,
        }));

        setLoading(false);
        navigation.jumpTo('Investment Summary', {});
    }

    return (
        <>
            <KeyboardAvoidingView>
                <View style={styles.container}>
                    <SafeAreaView style={styles.listContainer}>
                        <KeyboardAwareScrollView>
                            <Center>
                                <Box w="95%">
                                    <FormControl>
                                        <AmountWithCurrencyInput label={"Initial Investment"}
                                            initialAmount={initialAmount}
                                            onAmountChange={(newAmount: string) => {
                                                setInitialAmount(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                            }} />
                                        <AmountWithCurrencyInput label={"Additional Contribution"}
                                            initialAmount={additionalInvestment}
                                            onAmountChange={(newAmount: string) => {
                                                setAdditionalInvestment(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                            }} />
                                        <PercentInput label={"Return Rate"}
                                            initialValue={returnRate}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setReturnRate(newValue);
                                            }} />

                                        <ValueWithInputTypeToogle
                                            label={"Investment Period"}
                                            sliderVisible={true}
                                            inputType={investmentPeriodType}
                                            initialValue={investmentPeriod}
                                            onValueChange={(newValue: string, valueType: string) => {
                                                setInvestmentPeriod(newValue);
                                                setInvestmentPeriodType(valueType);
                                            }}
                                            allTypes={
                                                [
                                                    { "type": "yrs", "label": "years", multiplier: 1 / 12, "min": 0, "max": 30 },
                                                    { "type": "mths", "label": "months", multiplier: 12, "min": 0, "max": 360 }
                                                ]
                                            }
                                        />
                                        <SelectWithLabel
                                            label={"Contribution Frequency"}
                                            allOptions={[
                                                { label: "Monthly", value: "monthly" },
                                                { label: "Quarterly", value: "quarterly" },
                                                { label: "Half-Yearly", value: "half-yearly" },
                                                { label: "Yearly", value: "yearly" },
                                            ]}
                                            initialValue={contributionFrequency}
                                            onValueChange={(itemValue: string) => setContributionFrequency(itemValue)}
                                        />

                                        <SelectWithLabel
                                            label={"Compound Frequency"}
                                            allOptions={[
                                                { label: "Daily", value: "daily" },
                                                { label: "Weekly", value: "weekly" },
                                                { label: "Bi-Weekly", value: "bi-weekly" },
                                                { label: "Semimonthly", value: "semi-monthly" },
                                                { label: "Monthly", value: "monthly" },
                                                { label: "Quarterly", value: "quarterly" },
                                                { label: "Half-Yearly", value: "half-yearly" },
                                                { label: "Yearly", value: "yearly" },
                                            ]}
                                            initialValue={compoundFrequency}
                                            onValueChange={(itemValue: string) => setCompoundFrequency(itemValue)}
                                        />
                                    </FormControl>

                                    <Button
                                        style={styles.inputStyle}
                                        my={4}
                                        shadow={2}
                                        isLoading={loading}
                                        isLoadingText='Calculating...'
                                        onPress={() => {
                                            setCalculatorInputs();
                                        }}>Calculate</Button>
                                </Box>
                            </Center>
                        </KeyboardAwareScrollView>
                    </SafeAreaView>
                </View>
            </KeyboardAvoidingView>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});