import React from 'react';
import { View, Box, Button, FormControl, Center } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setMortgageCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IMortgageCalculatorInput } from 'src/models';
import { IStore } from 'src/redux/reducers';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import ValueOrPercentInput from 'src/components/common/ValueOrPercentInput';
import PercentInput from 'src/components/common/PercentInput';
import ValueWithInputTypeToogle from 'src/components/common/ValueWithInputTypeToogle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { DatePickerInput } from 'src/components/common/DatePickerInput';

export default function MortgageCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const calculatorInputs: IMortgageCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.mortgagecalculatorInput);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === calculatorInputs?.currency)?.Symbol || "$";

    const [downPaymentType, setDownpaymentType] = React.useState(calculatorInputs?.downPaymentType || currencySymbolFromCode);
    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(calculatorInputs?.currency || "USD");

    const [homePrice, setHomePrice] = React.useState(`${calculatorInputs?.homePrice || 1000000}`);
    const [downPayment, setDownPayment] = React.useState(`${calculatorInputs?.downPayment || 200000}`);

    const [loanTerm, setLoanTerm] = React.useState(`${calculatorInputs?.loanTerm || 30}`);
    const [interestRate, setInterestRate] = React.useState(`${calculatorInputs?.interestRate || 3.5}`);

    const currentDate = new Date();

    const [selectedMonth, setSelectedMonth] = React.useState(`${currentDate.getMonth() + 1}`);
    const [selectedYear, setSelectedYear] = React.useState(currentDate.getFullYear());
    const [loading, setLoading] = React.useState(false);


    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setMortgageCalculatorInput({
            currency: currencyCode,
            downPaymentType: downPaymentType,
            homePrice: parseFloat(homePrice) || 0,
            downPayment: parseFloat(downPayment) || 0,
            loanTerm: parseFloat(loanTerm) || 0,
            interestRate: parseFloat(interestRate) || 0,
            selectedMonth: parseInt(selectedMonth) || currentDate.getMonth() + 1,
            selectedYear: selectedYear
        }));

        setLoading(false);
        navigation.jumpTo('Mortgage Summary', {});
    }

    return (
        <>
            <View style={styles.container}>
                <SafeAreaView style={styles.listContainer}>
                    <KeyboardAwareScrollView>

                        <Center>
                            <Box w="95%">
                                <FormControl>
                                    <AmountWithCurrencyInput label={"Home Price"}
                                        initialAmount={homePrice}
                                        onAmountChange={(newAmount: string) => {
                                            setHomePrice(newAmount)
                                        }}
                                        initialCurrency={currencyCode}
                                        onCurrencyChange={(newCurrency: any) => {
                                            setCurrencyDisplay(newCurrency.Symbol);
                                            setCurrencyCode(newCurrency.CurrencyCode);
                                            setDownpaymentType(newCurrency.Symbol);
                                        }} />
                                    <ValueOrPercentInput
                                        initialValue={downPayment}
                                        maxAmount={homePrice}
                                        label={"Down Payment"}
                                        nonPercentType={"currency"}
                                        currency={currencyCode}
                                        inputType={downPaymentType}
                                        sliderVisible={true}
                                        sliderStepSize={10000}
                                        onValueChange={(newValue: string, valueType: string) => {
                                            setDownPayment(newValue);
                                            setDownpaymentType(valueType);
                                        }} />

                                    <ValueWithInputTypeToogle
                                        label={"Loan Term"}
                                        sliderVisible={true}
                                        inputType={"yrs"}
                                        initialValue={loanTerm}
                                        onValueChange={(newValue: string, valueType: string) => {
                                            setLoanTerm(newValue);
                                        }}
                                        allTypes={
                                            [
                                                { "type": "yrs", "label": "years", multiplier: 1, "min": 0, "max": 30 }
                                            ]
                                        }
                                    />
                                    <PercentInput label={"Interest Rate"}
                                        initialValue={interestRate}
                                        sliderVisible={true}
                                        onValueChange={(newValue: string) => {
                                            setInterestRate(newValue);
                                        }} />

                                    <DatePickerInput
                                        label={"Start Date"}
                                        initialValue={new Date()}
                                        onValueChange={(newDate: Date) => {
                                            const newMonth = newDate.getMonth() + 1;
                                            const newYear = newDate.getFullYear();
                                            setSelectedMonth(`${newMonth}`);
                                            setSelectedYear(newYear);
                                        }} />
                                </FormControl>

                                <Button
                                    style={styles.inputStyle}
                                    my={4}
                                    shadow={2}
                                    isLoading={loading}
                                    isLoadingText='Calculating...'
                                    onPress={() => {
                                        setCalculatorInputs();
                                    }}>Calculate</Button>
                            </Box>
                        </Center>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});