import { IUserSubscription } from "src/models";
import { createAction, createAsyncAction } from "typesafe-actions";

export const reset = createAction('RESET')<void>();

export const setUserSubscriptionStatus = createAction('SET_USER_SUBSCRIPTION_STATUS')<string>();

export const checkUserSubscriptionStatus = createAsyncAction(
    "scan/CHECK_SUBSCRIPTION_STATUS_REQUEST",
    "scan/CHECK_SUBSCRIPTION_STATUS_SUCCESS",
    "scan/CHECK_SUBSCRIPTION_STATUS_FAILURE"
)<string, IUserSubscription[], Error>();