import { ITipCalculatorInput } from "src/models/TipCalculatorInput";
import { formatCurrency } from "../formatUtils";

export const tipCalculator = (tipCalculatorInput: ITipCalculatorInput | undefined) => {
    if (tipCalculatorInput === undefined) {
        return [];
    }

    const { billAmount, tipPercent, numberOfPeople, currency } = tipCalculatorInput;
    const tip = billAmount * (tipPercent / 100);
    const total = billAmount + tip;
    const perPerson = total / numberOfPeople;
    return {
        "summary": [
            ["Tip", formatCurrency(tip, currency || "USD")],
            ["Total", formatCurrency(total, currency || "USD")],
            ["Per Person", formatCurrency(perPerson, currency || "USD")]
        ]
    }
}