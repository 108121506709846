import { ActionType, getType } from "typesafe-actions";
import { IJob, IJobRequest, IJobs } from "../../models";
import * as actions from "../actions/jobAction";

export interface IJobState {
    jobId?: string,
    jobRequest?: IJobRequest,
    createJob: ICreateJobState,
    createRealtimeJob: ICreateRealtimeJobState,
    queueJob: IQueueJobState,
    jobStatus: IJobStatusState,
    userJobs: IUserJobsState,
}

export interface ICreateJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}

export interface ICreateRealtimeJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly job?: IJob | null;
}

export interface IQueueJobState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: Boolean | null;
}

export interface IJobStatusState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly job?: IJob | null;
}

export interface IUserJobsState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly jobs?: IJobs | null;
    readonly lastFetch: Date;
}


export const initialJobState: IJobState = {
    jobId: undefined,
    jobRequest: undefined,
    createJob: {
        error: undefined,
        isFetching: false,
        success: false
    },
    createRealtimeJob: {
        error: undefined,
        isFetching: false,
        job: undefined
    },
    queueJob: {
        error: undefined,
        isFetching: false,
        success: false
    },
    jobStatus: {
        error: undefined,
        isFetching: false,
        job: undefined
    },
    userJobs: {
        error: undefined,
        isFetching: false,
        jobs: undefined,
        lastFetch: new Date()
    }
};

export const JobReducer = (
    state: IJobState = initialJobState,
    action: ActionType<typeof actions>
): IJobState => {
    switch (action.type) {
        case getType(actions.setJobId):
            return {
                ...state,
                jobId: action.payload
            };
        case getType(actions.setJobRequest):
            return {
                ...state,
                jobRequest: action.payload
            };
        case getType(actions.jobStatus.request):
            return {
                ...state,
                jobStatus: {
                    error: undefined,
                    isFetching: true,
                    job: undefined
                }
            };
        case getType(actions.jobStatus.success):
            return {
                ...state,
                jobStatus: {
                    error: undefined,
                    isFetching: false,
                    job: action.payload
                }
            };
        case getType(actions.jobStatus.failure):
            return {
                ...state,
                jobStatus: {
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.createJob.request):
            return {
                ...state,
                createJob: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.createJob.success):
            return {
                ...state,
                createJob: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.createJob.failure):
            return {
                ...state,
                createJob: {
                    ...state.createJob,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.createRealtimeJob.request):
            return {
                ...state,
                createRealtimeJob: {
                    error: undefined,
                    isFetching: true,
                    job: undefined
                }
            };
        case getType(actions.createRealtimeJob.success):
            return {
                ...state,
                createRealtimeJob: {
                    error: undefined,
                    isFetching: false,
                    job: action.payload
                }
            };
        case getType(actions.createRealtimeJob.failure):
            return {
                ...state,
                createRealtimeJob: {
                    ...state.createJob,
                    error: action.payload,
                    job: undefined,
                }
            };
        case getType(actions.queueJob.request):
            return {
                ...state,
                queueJob: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.queueJob.success):
            return {
                ...state,
                queueJob: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.queueJob.failure):
            return {
                ...state,
                queueJob: {
                    ...state.queueJob,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.pollJobStatus.request):
            return {
                ...state,
                jobStatus: {
                    error: undefined,
                    isFetching: true,
                    job: undefined
                }
            };
        case getType(actions.pollJobStatus.success):
            return {
                ...state,
                jobStatus: {
                    error: undefined,
                    isFetching: false,
                    job: action.payload
                }
            };
        case getType(actions.pollJobStatus.failure):
            return {
                ...state,
                jobStatus: {
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.userJobs.request):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: undefined,
                    isFetching: true,
                    jobs: undefined,
                }
            };
        case getType(actions.userJobs.success):
            return {
                ...state,
                userJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: action.payload,
                    lastFetch: new Date()
                }
            };
        case getType(actions.userJobs.failure):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.pollUserJobs.request):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: undefined,
                    isFetching: true,
                    jobs: undefined
                }
            };
        case getType(actions.pollUserJobs.success):
            return {
                ...state,
                userJobs: {
                    error: undefined,
                    isFetching: false,
                    jobs: action.payload,
                    lastFetch: new Date()
                }
            };
        case getType(actions.pollUserJobs.failure):
            return {
                ...state,
                userJobs: {
                    ...state.userJobs,
                    error: action.payload,
                    isFetching: false,
                }
            };
        default:
            return state;
    }
};