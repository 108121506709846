import React from 'react';
import { View, StyleSheet } from 'react-native';
import { APP_THEME } from "../../constants";

import ToolCategoryList from 'src/components/ToolCategoryList';
// import messaging from '@react-native-firebase/messaging';

export default function InfoPage({ navigation }) {

    return (
        <>
            <View style={styles.container}>
                <ToolCategoryList navigation={navigation} />
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
    },
    fab: {
        backgroundColor: '#4CAF50',
    },
    sectionText: {
        margin: 12,
        color: 'black',
        fontSize: 16,
        fontWeight: 'bold'
    },
    inputText: {
        marginHorizontal: 12,
        marginVertical: 4,
    },
    image: {
        marginVertical: 12,
        alignItems: 'center',
    },
    buttonStyle: { width: "95%", marginTop: 24, marginBottom: 24, backgroundColor: '#4CAF50', alignSelf: 'center' },
    progressBarStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
        backgroundColor: 'white',
        opacity: 0.7
    },
    progressTextStyle: {
        fontSize: 12,
        marginTop: 12
    },
    footer: {
        flexDirection: 'row',
        padding: 12,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: APP_THEME.primaryColor,
    },
    containerStyle: { backgroundColor: 'white', height: "80%", width: "95%", alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between' },
})
