import FetchError from "./errors";
import { IJob, IJobRequest, IJobs } from "../models";
import { customFetch } from "./shared";
import { MODEL_CONFIG } from "src/constants";

export const createJob = async (jobRequest: IJobRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetch(`/job`, {
      method: "POST",
      body: JSON.stringify(jobRequest)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const realtimeInferenceJob = async (jobId: string): Promise<IJob | null> => {
  try {
    const response = await customFetch(`/job/model/${MODEL_CONFIG.modelName}`, {
      method: "POST",
      body: JSON.stringify({
        "jobId": jobId
      })
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const queueJob = async (jobId: string): Promise<Boolean | null> => {
  try {
    const request = {
      "jobId": jobId
    }
    const response = await customFetch(`/job/queue/model/${MODEL_CONFIG.modelName}`, {
      method: "POST",
      body: JSON.stringify(request)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getJobs = async (username: string): Promise<IJobs | null> => {
  try {
    const response = await customFetch(`/user/${username}/jobs`)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};

export const getJob = async (jobId: string): Promise<IJob | null> => {
  try {
    const response = await customFetch(`/job/${jobId}`)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};