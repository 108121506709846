import { ISignedUrlMapResponse } from "src/models";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/uploadAction";

export interface IUploadState {
    readonly putPresignedUrl: IPresignedUrlState;
    readonly getPresignedUrl: IPresignedUrlState;
    readonly upload: IUploadObjectState;
}

export interface IPresignedUrlState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly presignedUrl?: ISignedUrlMapResponse;
}

export interface IUploadObjectState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly success: boolean;
}

export const initialUploadState: IUploadState = {
    putPresignedUrl: {
        error: undefined,
        isFetching: false,
        presignedUrl: undefined
    },
    getPresignedUrl: {
        error: undefined,
        isFetching: false,
        presignedUrl: undefined
    },
    upload: {
        error: undefined,
        isFetching: false,
        success: false
    }
};

export const UploadReducer = (
    state: IUploadState = initialUploadState,
    action: ActionType<typeof actions>
): IUploadState => {
    switch (action.type) {
        case getType(actions.putPresignedUrl.request):
            return {
                ...state,
                putPresignedUrl: {
                    error: undefined,
                    isFetching: true,
                    presignedUrl: undefined
                }
            };
        case getType(actions.putPresignedUrl.success):
            return {
                ...state,
                putPresignedUrl: {
                    error: undefined,
                    isFetching: false,
                    presignedUrl: action.payload
                }
            };
        case getType(actions.putPresignedUrl.failure):
            return {
                ...state,
                putPresignedUrl: {
                    error: action.payload,
                    isFetching: false,
                    presignedUrl: undefined
                }
            };
        case getType(actions.getPresignedUrl.request):
            return {
                ...state,
                getPresignedUrl: {
                    error: undefined,
                    isFetching: true,
                    presignedUrl: undefined
                }
            };
        case getType(actions.getPresignedUrl.success):
            return {
                ...state,
                getPresignedUrl: {
                    error: undefined,
                    isFetching: false,
                    presignedUrl: action.payload
                }
            };
        case getType(actions.getPresignedUrl.failure):
            return {
                ...state,
                getPresignedUrl: {
                    error: action.payload,
                    isFetching: false,
                    presignedUrl: undefined
                }
            };
        case getType(actions.uploadUsingPresignedUrl.request):
            return {
                ...state,
                upload: {
                    error: undefined,
                    isFetching: true,
                    success: false
                }
            };
        case getType(actions.uploadUsingPresignedUrl.success):
            return {
                ...state,
                upload: {
                    error: undefined,
                    isFetching: false,
                    success: action.payload
                }
            };
        case getType(actions.uploadUsingPresignedUrl.failure):
            return {
                ...state,
                upload: {
                    error: action.payload,
                    isFetching: false,
                    success: false
                }
            };

        default:
            return state;
    }
};