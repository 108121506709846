import React from 'react';
import { View, Box, Button, FormControl, Center, KeyboardAvoidingView } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setRsuTaxCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IRsuTaxCalculatorInput } from 'src/models';
import { IStore } from 'src/redux/reducers';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import ValueInput from 'src/components/common/ValueInput';
import ValueOrPercentInput from 'src/components/common/ValueOrPercentInput';
import PercentInput from 'src/components/common/PercentInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default function RsuTaxCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const calculatorInputs: IRsuTaxCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.rsuTaxCalculatorInput);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === calculatorInputs?.currency)?.Symbol || "$";

    const [sharesVestedType, setSharesVestedType] = React.useState("shares");
    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(calculatorInputs?.currency || "USD");

    const [sharePrice, setSharePrice] = React.useState(`${calculatorInputs?.sharePrice || 10}`);
    const [numberOfRsusGranted, setNumberOfRsusGranted] = React.useState(`${calculatorInputs?.numberOfSharesGranted || 1000}`);
    const [numberOfRsusVested, setNumberOfRsusVested] = React.useState(`${calculatorInputs?.numberOfSharesVested || 250}`);
    const [totalTaxPercent, setTotalTaxPercent] = React.useState(`${calculatorInputs?.taxPercent || 28.5}`);

    const currentDate = new Date();

    const [loading, setLoading] = React.useState(false);

    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setRsuTaxCalculatorInput({
            currency: currencyCode,
            sharePrice: parseFloat(sharePrice) || 0,
            numberOfSharesGranted: parseFloat(numberOfRsusGranted) || 0,
            numberOfSharesVested: parseFloat(numberOfRsusVested) || 0,
            taxPercent: parseFloat(totalTaxPercent) || 0,
        }));

        setLoading(false);
        navigation.jumpTo('RSU Tax Summary', {});
    }

    return (
        <>
            <KeyboardAvoidingView>
                <View style={styles.container}>
                    <SafeAreaView style={styles.listContainer}>
                        <KeyboardAwareScrollView>
                            <Center>
                                <Box w="95%">
                                    <FormControl>
                                        <AmountWithCurrencyInput label={"Company Share Price at Vesting"}
                                            initialAmount={sharePrice}
                                            onAmountChange={(newAmount: string) => {
                                                setSharePrice(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setSharesVestedType(newCurrency.Symbol);
                                            }} />

                                        <ValueInput label={"Number of Shared Granted"}
                                            initialValue={numberOfRsusGranted}
                                            sliderVisible={false}
                                            maxSliderValue={0}
                                            onValueChange={(newValue: string) => {
                                                setNumberOfRsusGranted(newValue);
                                            }} />
                                        <ValueOrPercentInput
                                            initialValue={numberOfRsusVested}
                                            maxAmount={numberOfRsusGranted}
                                            label={"Shares Vested"}
                                            nonPercentType={"shares"}
                                            currency={currencyCode}
                                            inputType={sharesVestedType}
                                            sliderVisible={true}
                                            sliderStepSize={10000}
                                            onValueChange={(newValue: string, valueType: string) => {
                                                setNumberOfRsusVested(newValue);
                                                setSharesVestedType(valueType);
                                            }} />
                                        <PercentInput
                                            initialValue={totalTaxPercent}
                                            label={"Total Tax %"}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setTotalTaxPercent(newValue);
                                            }} />
                                    </FormControl>

                                    <Button
                                        style={styles.inputStyle}
                                        my={4}
                                        shadow={2}
                                        isLoading={loading}
                                        isLoadingText='Calculating...'
                                        onPress={() => {
                                            setCalculatorInputs();
                                        }}>Calculate</Button>
                                </Box>
                            </Center>
                        </KeyboardAwareScrollView>
                    </SafeAreaView>
                </View>
            </KeyboardAvoidingView>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});