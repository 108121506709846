import * as Effects from "redux-saga/effects";

import * as UserAction from "../actions/userAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";
import { IUserSubscription } from "src/models";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* checkUserSubscriptionStatus(action: ReturnType<typeof UserAction.checkUserSubscriptionStatus.request>): any {
  try {
    const response: IUserSubscription[] = yield call(api.checkUserSubscriptionStatus, action.payload);

    yield put(UserAction.checkUserSubscriptionStatus.success(response));

    for(let subscription of response) {
      if (subscription.status === "ACTIVE" && subscription.expiry > (new Date().getTime() / 1000)) {
        yield put(UserAction.setUserSubscriptionStatus("ACTIVE"));
        return;
      }
    }
    yield put(UserAction.setUserSubscriptionStatus("EXPIRED"));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UserAction.checkUserSubscriptionStatus.failure(error));
    yield put(UserAction.setUserSubscriptionStatus("UNKNOWN"));
  }
}
/*
 * WATCHERS
 */

export function* watchCheckUserSubscriptionStatus() {
  yield takeEvery(UserAction.checkUserSubscriptionStatus.request, checkUserSubscriptionStatus);
}

export default function* root() {
  yield all([fork(watchCheckUserSubscriptionStatus)]);
}