import { IInvestmentCalculatorInput } from "src/models";
import { formatCurrency } from "../formatUtils";

export const investmentCalculator = (investmentCalculator: IInvestmentCalculatorInput | undefined) => {
    if (investmentCalculator === undefined) {
        return [];
    }

    const { currency,
        initialInvestment,
        additionalInvestment,
        investmentPeriod,
        investmentPeriodType,
        returnRate,
        contributionFrequency,
        compoundFrequency } = investmentCalculator;

    const contributionFrequencyInYears = contributionFrequency === "yearly"
        ? 1 : contributionFrequency === "half-yearly"
            ? 2 : contributionFrequency === "quarterly"
                ? 4 : 12;

    const compoundFrequencyInYears = compoundFrequency === "yearly"
        ? 1 : compoundFrequency === "half-yearly"
            ? 2 : compoundFrequency === "quarterly"
                ? 4 : compoundFrequency === "monthly"
                    ? 12 : compoundFrequency === "semi-monthly"
                        ? 24 : compoundFrequency === "bi-weekly"
                            ? 26 : compoundFrequency === "weekly"
                                ? 52 : 365;

    const interestRate = returnRate / 100;
    // Calculate the total number of contributions
    const investmentPeriodInYears = investmentPeriodType === "yrs" ? investmentPeriod : investmentPeriod / 12;

    const totalContributions = contributionFrequencyInYears * investmentPeriodInYears;

    // Calculate the compounded interest rate per period
    const compoundedInterestRate = Math.pow(1 + interestRate / compoundFrequencyInYears, compoundFrequencyInYears / contributionFrequencyInYears) - 1;

    let balance = initialInvestment * Math.pow(1 + compoundedInterestRate, totalContributions);
    balance += additionalInvestment * ((Math.pow(1 + compoundedInterestRate, totalContributions) - 1) / compoundedInterestRate);

    let totalAdditionalInvestment = additionalInvestment * totalContributions;

    let totalInterest = balance - initialInvestment - totalAdditionalInvestment;

    return {
        "summary": [
            ["Initial amount", formatCurrency(initialInvestment, currency)],
            ["Additional amount", formatCurrency(totalAdditionalInvestment, currency)],
            ["Interest earned", formatCurrency(totalInterest, currency)],
            ["Total", formatCurrency(balance, currency)],
        ],
        "pieChartData": [
            {
                label: 'Initial amount',
                svg: { fill: '#18c983' },
                value: initialInvestment,
                percent: ((initialInvestment) / balance) * 100
            },
            {
                label: 'Contributions',
                svg: { fill: '#325ea8' },
                value: totalAdditionalInvestment || 0,
                percent: ((totalAdditionalInvestment || 0) / balance) * 100
            },
            {
                label: 'Interest',
                svg: { fill: '#db692c' },
                value: totalInterest || 0,
                percent: ((totalInterest || 0) / balance) * 100
            }
        ]
    }
}