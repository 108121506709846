import FetchError from "./errors";
import { customFetch } from "./shared";

export const checkUserSubscriptionStatus = async (username: string): Promise<string | null> => {
  try {
    const response = await customFetch(`/user/${username}/subscription`, {
      method: "GET"
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};