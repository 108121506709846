import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/paymentAction";
import { ICreateSessionResponse } from "../../models";

export interface IPaymentState {
    readonly checkoutSession: ICheckoutSessionState;
}

export interface ICheckoutSessionState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly response: ICreateSessionResponse | undefined;
}

export const initialPaymentState: IPaymentState = {
    checkoutSession: {
        error: undefined,
        isFetching: false,
        response: undefined
    }
};

export const PaymentReducer = (
    state: IPaymentState = initialPaymentState,
    action: ActionType<typeof actions>
): IPaymentState => {
    switch (action.type) {
        case getType(actions.checkoutSession.request):
            return {
                ...state,
                checkoutSession: {
                    ...state.checkoutSession,
                    isFetching: true,
                }
            };
        case getType(actions.checkoutSession.success):
            return {
                ...state,
                checkoutSession: {
                    error: undefined,
                    isFetching: false,
                    response: action.payload
                }
            };
        case getType(actions.checkoutSession.failure):
            return {
                ...state,
                checkoutSession: {
                    error: action.payload,
                    isFetching: false,
                    response: undefined
                }
            };
        default:
            return state;
    }
};