import React from 'react';

import { FormControl, Select, CheckIcon } from 'native-base';

export default function SelectWithLabel({
    label,
    initialValue,
    allOptions,
    onValueChange }) {

    const [selectedValue, setSelectedValue] = React.useState<string>(initialValue);

    return (<>
        <FormControl.Label>{label}</FormControl.Label>
        <Select
            selectedValue={selectedValue}
            minWidth={200}
            accessibilityLabel={label}
            placeholder={label}
            onValueChange={(itemValue) => {
                setSelectedValue(itemValue);
                onValueChange(itemValue);
            }}
            _selectedItem={{
                bg: "cyan.600",
                endIcon: <CheckIcon size={4} />,
            }}
        >
            {
                allOptions.map((item: any, index: number) => {
                    return (<Select.Item key={index} label={item.label} value={item.value} />);
                })
            }
        </Select>
    </>);
};