import "intl";

import "intl/locale-data/jsonp/en";

import I18n from 'react-native-i18n';
const locale = I18n.currentLocale() || "en-US";
import { allCurrencies } from "src/data/currencyData";

export const formatCurrency = (value, currency) => {
    try {
        const formattedNumber = new Intl.NumberFormat(locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
        }).format(value)
        const selectedCurrency = allCurrencies.find(c => c['CurrencyCode'] === currency);
        const symbol = selectedCurrency.Symbol === "" ? selectedCurrency.CurrencyCode : selectedCurrency.Symbol;

        const formattedCurrency = `${symbol} ${formattedNumber}`;
        return formattedCurrency;
    } catch (error) {
        console.log("Formatting error occurred", error);
        return `${currency} ${value.toFixed(2)}`
    }
}