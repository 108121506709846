import React from 'react';
import { View, Box, Button, FormControl, Center } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setInflationCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IStore } from 'src/redux/reducers';
import TipCalculatorSummary from '../Summary';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import PercentInput from 'src/components/common/PercentInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { DatePickerInput } from 'src/components/common/DatePickerInput';
import { IInflationCalculatorInput } from 'src/models/InflationCalculatorInput';

export default function InflationCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const input: IInflationCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.inflationCalculatorInput);

    console.log("input", input);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === input?.currency)?.Symbol || "$";

    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(input?.currency || "USD");

    const [amount, setAmount] = React.useState(`${input?.amount || 100}`);
    const [inflationRate, setInflationRate] = React.useState(`${input?.inflationRate || 15}`);

    const [endDate, setEndDate] = React.useState(input?.endDate || new Date());

    const [loading, setLoading] = React.useState(false);

    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setInflationCalculatorInput({
            currency: currencyCode,
            amount: parseFloat(amount) || 0,
            inflationRate: parseFloat(inflationRate) || 0,
            endDate: endDate
        }));

        setLoading(false);
    }

    return (
        <>
            <View style={styles.container}>
                <SafeAreaView style={styles.listContainer}>
                    <KeyboardAwareScrollView>
                        <Center>
                            <Box w="95%">
                                <FormControl>
                                    <AmountWithCurrencyInput label={"Amount"}
                                        initialAmount={amount}
                                        onAmountChange={(newAmount: string) => {
                                            setAmount(newAmount)
                                        }}
                                        initialCurrency={currencyCode}
                                        onCurrencyChange={(newCurrency: any) => {
                                            setCurrencyDisplay(newCurrency.Symbol);
                                            setCurrencyCode(newCurrency.CurrencyCode);
                                        }} />
                                    <PercentInput label={"Inflation Rate"}
                                        initialValue={inflationRate}
                                        sliderVisible={true}
                                        onValueChange={(newValue: string) => {
                                            setInflationRate(newValue);
                                        }} />

                                    <DatePickerInput
                                        label="End Date"
                                        initialValue={endDate}
                                        onValueChange={(value: Date) => setEndDate(value)}
                                    />
                                </FormControl>

                                <Button
                                    style={styles.inputStyle}
                                    my={4}
                                    isLoading={loading}
                                    isLoadingText='Calculating...'
                                    onPress={() => {
                                        setCalculatorInputs();
                                    }}>Calculate</Button>

                                <TipCalculatorSummary />
                            </Box>
                        </Center>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});