import React from 'react';

import { Text, FormControl, Input, InputGroup, Slider, InputRightAddon } from 'native-base';

// {"type": "month", "label": "months", multiplier: 12, "min": 0, "max": 360},
// 
export default function ValueWithInputTypeToogle({
    inputType,
    label,
    allTypes,
    initialValue,
    sliderVisible,
    onValueChange }) {

    const [value, setValue] = React.useState(`${initialValue || 0}`);

    const [valueType, setValueType] = React.useState(inputType || allTypes[0].type);
    const [maxSliderValue, setMaxSliderValue] = React.useState(allTypes[0].max || 100);
    const [minSliderValue, setMinSliderValue] = React.useState(allTypes[0].min || 0);

    const handleValueTypeClicked = () => {
        const currentTypeIndex = allTypes.findIndex((x: any) => x.type === valueType);
        const nextTypeIndex = currentTypeIndex === allTypes.length - 1 ? 0 : currentTypeIndex + 1;
        const nextType = allTypes[nextTypeIndex];

        const newValue = Math.floor(parseFloat(value) * nextType.multiplier);
        setValueType(nextType.type);
        setValue(`${newValue}`);
        onValueChange(newValue, nextType.type);
        setMaxSliderValue(nextType.max || 100);
        setMinSliderValue(nextType.min || 0);
    }

    return (<>
        <FormControl.Label>{label}</FormControl.Label>
        <InputGroup>
            <Input w={"85%"}
                value={value}
                onChangeText={(text) => {
                    setValue(text);
                    onValueChange(text, valueType);
                }}
                keyboardType='decimal-pad'
                placeholder={label} />
            <InputRightAddon w={"15%"} children={
                <Text minWidth={25}
                    textAlign={"center"}
                    justifyContent={"center"}
                    onPress={handleValueTypeClicked}
                    fontSize="md" color="gray.500">{valueType}</Text>
            } />
        </InputGroup>
        {
            sliderVisible &&
            <Slider
                defaultValue={parseFloat(value) || 0}
                value={parseFloat(value) || 0}
                minValue={minSliderValue}
                maxValue={maxSliderValue}
                my={3}
                onChange={(value) => {
                    setValue(value.toString());
                    onValueChange(value.toString(), valueType);
                }}
                accessibilityLabel={label} step={1}>
                <Slider.Track shadow={2}>
                    <Slider.FilledTrack />
                </Slider.Track>
                <Slider.Thumb shadow={3} />
            </Slider>
        }
    </>);
};