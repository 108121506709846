import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { IInvestmentCalculatorInput } from 'src/models';
import { investmentCalculator } from 'src/utils/finance/investmentUtils';
import SummaryView from 'src/components/common/SummaryView';

export default function InvestmentCalculatorSummary() {

    const calculatorInputs: IInvestmentCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.investmentCalculatorInput);

    const [result, setResult] = React.useState<any>({});

    React.useEffect(() => {
        const result = investmentCalculator(calculatorInputs);
        setResult(result || []);
    }, [calculatorInputs]);

    return <SummaryView summary={result} />;
};