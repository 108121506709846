import MortgageCalculatorTabs from '../components/Calculator/Financial/Mortgage/Tabs';
import TipCalculatorInput from '../components/Calculator/Financial/Tip/Input';
import RsuTaxCalculatorTabs from '../components/Calculator/Financial/RSUTax/Tabs';
import InvestmentCalculatorTabs from '../components/Calculator/Financial/Investment/Tabs';
import RealEstateROICalculatorTabs from '../components/Calculator/Financial/RealEstateROI/Tabs';

import { ToolCategory } from '../models/Tool';
import DaysBetweenTwoDatesCalculatorInput from 'src/components/Calculator/Date/DaysBetweenTwoDates/Input';
import AgeCalculatorInput from 'src/components/Calculator/Date/Age/Input';
import InflationCalculatorInput from 'src/components/Calculator/Financial/Inflation/Input';

export const allTools: ToolCategory[] = [
    {
        category: "financial",
        categoryDisplayName: "Financial Calculators",
        icon: "fas fa-dollar-sign",
        tools: [
            {
                name: "mortgage",
                category: "financial",
                displayName: "Mortgage Calculator",
                description: "Calculate your monthly mortgage payments",
                icon: "bank",
                pageLink: "Mortgage Calculator",
                component: MortgageCalculatorTabs,
                favorite: false
            },
            {
                name: "real-estate-roi",
                category: "financial",
                displayName: "Real Estate ROI Calculator",
                description: "Calculate your return on investment for a real estate property",
                icon: "bank",
                component: RealEstateROICalculatorTabs,
                pageLink: "Real Estate ROI Calculator",
                favorite: false
            },
            {
                name: "tip",
                category: "financial",
                displayName: "Tip Calculator",
                description: "Calculate how much to tip your server",
                icon: "cash",
                pageLink: "Tip Calculator",
                component: TipCalculatorInput,
                favorite: false
            },
            {
                name: "rsutax",
                category: "financial",
                displayName: "RSU Tax Calculator",
                description: "Calculate how much tax you will pay on your vested RSUs",
                icon: "cash",
                pageLink: "RSU Tax Calculator",
                component: RsuTaxCalculatorTabs,
                favorite: false
            },
            {
                name: "investment",
                category: "financial",
                displayName: "Investment Calculator",
                description: "Calculate how much your investment will be worth in the future",
                icon: "cash",
                pageLink: "Investment Calculator",
                component: InvestmentCalculatorTabs,
                favorite: false
            },
            {
                name: "inflation",
                category: "financial",
                displayName: "Inflation Calculator",
                description: "Calculate how much your money will be worth in the future",
                icon: "cash",
                pageLink: "Inflation Calculator",
                component: InflationCalculatorInput,
                favorite: false
            }
        ]
    },
    {
        category: "date",
        categoryDisplayName: "Date Calculators",
        icon: "fas fa-dollar-sign",
        tools: [
            {
                name: "days-between-two-dates",
                category: "date",
                displayName: "Days Between Two Dates Calculator",
                description: "Calculate the number of days between two dates",
                icon: "calendar-today",
                pageLink: "Days Between Two Dates Calculator",
                component: DaysBetweenTwoDatesCalculatorInput,
                favorite: false
            },
            {
                name: "age",
                category: "date",
                displayName: "Age Calculator",
                description: "Calculate your age in years, months, and days",
                icon: "calendar-today",
                pageLink: "Age Calculator",
                component: AgeCalculatorInput,
                favorite: false
            }
        ]
    }
]