import React from 'react';

import { Text, FormControl, Input, InputGroup, InputLeftAddon, Slider, InputRightAddon } from 'native-base';
import { allCurrencies } from 'src/data/currencyData';

export default function ValueOrPercentInput({
    inputType,
    label,
    nonPercentType,
    currency,
    initialValue,
    maxAmount,
    sliderVisible,
    sliderStepSize,
    onValueChange }) {

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === currency)?.Symbol || "$";

    const [value, setValue] = React.useState(`${initialValue || 0}`);

    const [valueNonPercentType, setValueNonPercentType] = React.useState(nonPercentType === "currency" ? currencySymbolFromCode : nonPercentType);
    const [valueType, setValueType] = React.useState(inputType || currencySymbolFromCode);

    const handleValueTypeClicked = () => {
        let amountNumber = parseFloat(value) || 0;
        let maxAmountNumber = parseFloat(maxAmount) || 0;
        if (valueType === "%") {
            setValueType(valueNonPercentType);
            setValue(`${(amountNumber / 100) * maxAmountNumber}`);
            onValueChange(`${(amountNumber / 100) * maxAmountNumber}`, valueNonPercentType);
        } else {
            setValueType("%");
            setValue(`${(amountNumber / maxAmountNumber) * 100}`);
            onValueChange(`${(amountNumber / maxAmountNumber) * 100}`, "%");
        }
    }

    return (<>
        <FormControl.Label>{label}</FormControl.Label>
        <InputGroup>
            {
                nonPercentType === "currency" &&
                <InputLeftAddon w={"15%"} children={
                    <Text minWidth={25}
                        textAlign={"center"}
                        justifyContent={"center"}
                        onPress={handleValueTypeClicked}
                        fontSize="md" color="gray.500">{valueType}</Text>
                } />
            }
            <Input w={"85%"}
                value={value}
                onChangeText={(text) => {
                    setValue(text);
                    onValueChange(text, valueType);
                }}
                keyboardType='decimal-pad'
                placeholder={label} />
            {
                !(nonPercentType === "currency") &&
                <InputRightAddon w={"15%"} children={
                    <Text minWidth={25}
                        textAlign={"center"}
                        justifyContent={"center"}
                        onPress={handleValueTypeClicked}
                        fontSize="md" color="gray.500">{valueType}</Text>
                } />
            }
        </InputGroup>
        {
            sliderVisible &&
            valueType === "%" &&
            <Slider
                defaultValue={parseFloat(value) || 0}
                value={parseFloat(value) || 0}
                minValue={0}
                maxValue={100}
                my={3}
                onChange={(value) => {
                    setValue(value.toString());
                    onValueChange(value.toString(), valueType);
                }}
                accessibilityLabel={label} step={1}>
                <Slider.Track shadow={2}>
                    <Slider.FilledTrack />
                </Slider.Track>
                <Slider.Thumb shadow={3} />
            </Slider>
        }
        {
            sliderVisible &&
            valueType !== "%" &&
            <Slider
                defaultValue={parseFloat(value) || 0}
                value={parseFloat(value) || 0}
                minValue={0}
                maxValue={parseFloat(maxAmount) || 0}
                my={3}
                onChange={(value) => {
                    setValue(value.toString());
                    onValueChange(value.toString(), valueType);
                }}
                accessibilityLabel={label} step={sliderStepSize}>
                <Slider.Track shadow={2}>
                    <Slider.FilledTrack />
                </Slider.Track>
                <Slider.Thumb shadow={3} />
            </Slider>
        }
    </>);
};