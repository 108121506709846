export const allCurrencies = [
    {
      "CurrencyCode": "AED",
      "Name": "UAE Dirham",
      "Symbol": ""
    },
    {
      "CurrencyCode": "AFN",
      "Name": "Afghani",
      "Symbol": "؋"
    },
    {
      "CurrencyCode": "ALL",
      "Name": "Lek",
      "Symbol": "Lek"
    },
    {
      "CurrencyCode": "AMD",
      "Name": "Armenian Dram",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ANG",
      "Name": "Netherlands Antillian Guilder",
      "Symbol": "ƒ"
    },
    {
      "CurrencyCode": "AOA",
      "Name": "Kwanza",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ARS",
      "Name": "Argentine Peso",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "AUD",
      "Name": "Australian Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "AWG",
      "Name": "Aruban Guilder",
      "Symbol": "ƒ"
    },
    {
      "CurrencyCode": "AZN",
      "Name": "Azerbaijanian Manat",
      "Symbol": "ман"
    },
    {
      "CurrencyCode": "BAM",
      "Name": "Convertible Marks",
      "Symbol": "KM"
    },
    {
      "CurrencyCode": "BBD",
      "Name": "Barbados Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "BDT",
      "Name": "Taka",
      "Symbol": ""
    },
    {
      "CurrencyCode": "BGN",
      "Name": "Bulgarian Lev",
      "Symbol": "лв"
    },
    {
      "CurrencyCode": "BHD",
      "Name": "Bahraini Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "BIF",
      "Name": "Burundi Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "BMD",
      "Name": "Bermudian Dollar (customarily known as Bermuda Dollar)",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "BND",
      "Name": "Brunei Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "BOB BOV",
      "Name": "Boliviano Mvdol",
      "Symbol": "$b"
    },
    {
      "CurrencyCode": "BRL",
      "Name": "Brazilian Real",
      "Symbol": "R$"
    },
    {
      "CurrencyCode": "BSD",
      "Name": "Bahamian Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "BWP",
      "Name": "Pula",
      "Symbol": "P"
    },
    {
      "CurrencyCode": "BYR",
      "Name": "Belarussian Ruble",
      "Symbol": "p."
    },
    {
      "CurrencyCode": "BZD",
      "Name": "Belize Dollar",
      "Symbol": "BZ$"
    },
    {
      "CurrencyCode": "CAD",
      "Name": "Canadian Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "CDF",
      "Name": "Congolese Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "CHF",
      "Name": "Swiss Franc",
      "Symbol": "CHF"
    },
    {
      "CurrencyCode": "CLP CLF",
      "Name": "Chilean Peso Unidades de fomento",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "CNY",
      "Name": "Yuan Renminbi",
      "Symbol": "¥"
    },
    {
      "CurrencyCode": "COP COU",
      "Name": "Colombian Peso Unidad de Valor Real",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "CRC",
      "Name": "Costa Rican Colon",
      "Symbol": "₡"
    },
    {
      "CurrencyCode": "CUP CUC",
      "Name": "Cuban Peso Peso Convertible",
      "Symbol": "₱"
    },
    {
      "CurrencyCode": "CVE",
      "Name": "Cape Verde Escudo",
      "Symbol": ""
    },
    {
      "CurrencyCode": "CZK",
      "Name": "Czech Koruna",
      "Symbol": "Kč"
    },
    {
      "CurrencyCode": "DJF",
      "Name": "Djibouti Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "DKK",
      "Name": "Danish Krone",
      "Symbol": "kr"
    },
    {
      "CurrencyCode": "DOP",
      "Name": "Dominican Peso",
      "Symbol": "RD$"
    },
    {
      "CurrencyCode": "DZD",
      "Name": "Algerian Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "EEK",
      "Name": "Kroon",
      "Symbol": ""
    },
    {
      "CurrencyCode": "EGP",
      "Name": "Egyptian Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "ERN",
      "Name": "Nakfa",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ETB",
      "Name": "Ethiopian Birr",
      "Symbol": ""
    },
    {
      "CurrencyCode": "EUR",
      "Name": "Euro",
      "Symbol": "€"
    },
    {
      "CurrencyCode": "FJD",
      "Name": "Fiji Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "FKP",
      "Name": "Falkland Islands Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "GBP",
      "Name": "Pound Sterling",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "GEL",
      "Name": "Lari",
      "Symbol": ""
    },
    {
      "CurrencyCode": "GHS",
      "Name": "Cedi",
      "Symbol": ""
    },
    {
      "CurrencyCode": "GIP",
      "Name": "Gibraltar Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "GMD",
      "Name": "Dalasi",
      "Symbol": ""
    },
    {
      "CurrencyCode": "GNF",
      "Name": "Guinea Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "GTQ",
      "Name": "Quetzal",
      "Symbol": "Q"
    },
    {
      "CurrencyCode": "GYD",
      "Name": "Guyana Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "HKD",
      "Name": "Hong Kong Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "HNL",
      "Name": "Lempira",
      "Symbol": "L"
    },
    {
      "CurrencyCode": "HRK",
      "Name": "Croatian Kuna",
      "Symbol": "kn"
    },
    {
      "CurrencyCode": "HTG USD",
      "Name": "Gourde US Dollar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "HUF",
      "Name": "Forint",
      "Symbol": "Ft"
    },
    {
      "CurrencyCode": "IDR",
      "Name": "Rupiah",
      "Symbol": "Rp"
    },
    {
      "CurrencyCode": "ILS",
      "Name": "New Israeli Sheqel",
      "Symbol": "₪"
    },
    {
      "CurrencyCode": "INR",
      "Name": "Indian Rupee",
      "Symbol": ""
    },
    {
      "CurrencyCode": "INR BTN",
      "Name": "Indian Rupee Ngultrum",
      "Symbol": ""
    },
    {
      "CurrencyCode": "IQD",
      "Name": "Iraqi Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "IRR",
      "Name": "Iranian Rial",
      "Symbol": "﷼"
    },
    {
      "CurrencyCode": "ISK",
      "Name": "Iceland Krona",
      "Symbol": "kr"
    },
    {
      "CurrencyCode": "JMD",
      "Name": "Jamaican Dollar",
      "Symbol": "J$"
    },
    {
      "CurrencyCode": "JOD",
      "Name": "Jordanian Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "JPY",
      "Name": "Yen",
      "Symbol": "¥"
    },
    {
      "CurrencyCode": "KES",
      "Name": "Kenyan Shilling",
      "Symbol": ""
    },
    {
      "CurrencyCode": "KGS",
      "Name": "Som",
      "Symbol": "лв"
    },
    {
      "CurrencyCode": "KHR",
      "Name": "Riel",
      "Symbol": "៛"
    },
    {
      "CurrencyCode": "KMF",
      "Name": "Comoro Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "KPW",
      "Name": "North Korean Won",
      "Symbol": "₩"
    },
    {
      "CurrencyCode": "KRW",
      "Name": "Won",
      "Symbol": "₩"
    },
    {
      "CurrencyCode": "KWD",
      "Name": "Kuwaiti Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "KYD",
      "Name": "Cayman Islands Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "KZT",
      "Name": "Tenge",
      "Symbol": "лв"
    },
    {
      "CurrencyCode": "LAK",
      "Name": "Kip",
      "Symbol": "₭"
    },
    {
      "CurrencyCode": "LBP",
      "Name": "Lebanese Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "LKR",
      "Name": "Sri Lanka Rupee",
      "Symbol": "₨"
    },
    {
      "CurrencyCode": "LRD",
      "Name": "Liberian Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "LTL",
      "Name": "Lithuanian Litas",
      "Symbol": "Lt"
    },
    {
      "CurrencyCode": "LVL",
      "Name": "Latvian Lats",
      "Symbol": "Ls"
    },
    {
      "CurrencyCode": "LYD",
      "Name": "Libyan Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MAD",
      "Name": "Moroccan Dirham",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MDL",
      "Name": "Moldovan Leu",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MGA",
      "Name": "Malagasy Ariary",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MKD",
      "Name": "Denar",
      "Symbol": "ден"
    },
    {
      "CurrencyCode": "MMK",
      "Name": "Kyat",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MNT",
      "Name": "Tugrik",
      "Symbol": "₮"
    },
    {
      "CurrencyCode": "MOP",
      "Name": "Pataca",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MRO",
      "Name": "Ouguiya",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MUR",
      "Name": "Mauritius Rupee",
      "Symbol": "₨"
    },
    {
      "CurrencyCode": "MVR",
      "Name": "Rufiyaa",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MWK",
      "Name": "Kwacha",
      "Symbol": ""
    },
    {
      "CurrencyCode": "MXN MXV",
      "Name": "Mexican Peso Mexican Unidad de Inversion (UDI)",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "MYR",
      "Name": "Malaysian Ringgit",
      "Symbol": "RM"
    },
    {
      "CurrencyCode": "MZN",
      "Name": "Metical",
      "Symbol": "MT"
    },
    {
      "CurrencyCode": "NGN",
      "Name": "Naira",
      "Symbol": "₦"
    },
    {
      "CurrencyCode": "NIO",
      "Name": "Cordoba Oro",
      "Symbol": "C$"
    },
    {
      "CurrencyCode": "NOK",
      "Name": "Norwegian Krone",
      "Symbol": "kr"
    },
    {
      "CurrencyCode": "NPR",
      "Name": "Nepalese Rupee",
      "Symbol": "₨"
    },
    {
      "CurrencyCode": "NZD",
      "Name": "New Zealand Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "OMR",
      "Name": "Rial Omani",
      "Symbol": "﷼"
    },
    {
      "CurrencyCode": "PAB USD",
      "Name": "Balboa US Dollar",
      "Symbol": "B/."
    },
    {
      "CurrencyCode": "PEN",
      "Name": "Nuevo Sol",
      "Symbol": "S/."
    },
    {
      "CurrencyCode": "PGK",
      "Name": "Kina",
      "Symbol": ""
    },
    {
      "CurrencyCode": "PHP",
      "Name": "Philippine Peso",
      "Symbol": "Php"
    },
    {
      "CurrencyCode": "PKR",
      "Name": "Pakistan Rupee",
      "Symbol": "₨"
    },
    {
      "CurrencyCode": "PLN",
      "Name": "Zloty",
      "Symbol": "zł"
    },
    {
      "CurrencyCode": "PYG",
      "Name": "Guarani",
      "Symbol": "Gs"
    },
    {
      "CurrencyCode": "QAR",
      "Name": "Qatari Rial",
      "Symbol": "﷼"
    },
    {
      "CurrencyCode": "RON",
      "Name": "New Leu",
      "Symbol": "lei"
    },
    {
      "CurrencyCode": "RSD",
      "Name": "Serbian Dinar",
      "Symbol": "Дин."
    },
    {
      "CurrencyCode": "RUB",
      "Name": "Russian Ruble",
      "Symbol": "руб"
    },
    {
      "CurrencyCode": "RWF",
      "Name": "Rwanda Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "SAR",
      "Name": "Saudi Riyal",
      "Symbol": "﷼"
    },
    {
      "CurrencyCode": "SBD",
      "Name": "Solomon Islands Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "SCR",
      "Name": "Seychelles Rupee",
      "Symbol": "₨"
    },
    {
      "CurrencyCode": "SDG",
      "Name": "Sudanese Pound",
      "Symbol": ""
    },
    {
      "CurrencyCode": "SEK",
      "Name": "Swedish Krona",
      "Symbol": "kr"
    },
    {
      "CurrencyCode": "SGD",
      "Name": "Singapore Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "SHP",
      "Name": "Saint Helena Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "SLL",
      "Name": "Leone",
      "Symbol": ""
    },
    {
      "CurrencyCode": "SOS",
      "Name": "Somali Shilling",
      "Symbol": "S"
    },
    {
      "CurrencyCode": "SRD",
      "Name": "Surinam Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "STD",
      "Name": "Dobra",
      "Symbol": ""
    },
    {
      "CurrencyCode": "SVC USD",
      "Name": "El Salvador Colon US Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "SYP",
      "Name": "Syrian Pound",
      "Symbol": "£"
    },
    {
      "CurrencyCode": "SZL",
      "Name": "Lilangeni",
      "Symbol": ""
    },
    {
      "CurrencyCode": "THB",
      "Name": "Baht",
      "Symbol": "฿"
    },
    {
      "CurrencyCode": "TJS",
      "Name": "Somoni",
      "Symbol": ""
    },
    {
      "CurrencyCode": "TMT",
      "Name": "Manat",
      "Symbol": ""
    },
    {
      "CurrencyCode": "TND",
      "Name": "Tunisian Dinar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "TOP",
      "Name": "Pa'anga",
      "Symbol": ""
    },
    {
      "CurrencyCode": "TRY",
      "Name": "Turkish Lira",
      "Symbol": "TL"
    },
    {
      "CurrencyCode": "TTD",
      "Name": "Trinidad and Tobago Dollar",
      "Symbol": "TT$"
    },
    {
      "CurrencyCode": "TWD",
      "Name": "New Taiwan Dollar",
      "Symbol": "NT$"
    },
    {
      "CurrencyCode": "TZS",
      "Name": "Tanzanian Shilling",
      "Symbol": ""
    },
    {
      "CurrencyCode": "UAH",
      "Name": "Hryvnia",
      "Symbol": "₴"
    },
    {
      "CurrencyCode": "UGX",
      "Name": "Uganda Shilling",
      "Symbol": ""
    },
    {
      "CurrencyCode": "USD",
      "Name": "US Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "UYU UYI",
      "Name": "Peso Uruguayo Uruguay Peso en Unidades Indexadas",
      "Symbol": "$U"
    },
    {
      "CurrencyCode": "UZS",
      "Name": "Uzbekistan Sum",
      "Symbol": "лв"
    },
    {
      "CurrencyCode": "VEF",
      "Name": "Bolivar Fuerte",
      "Symbol": "Bs"
    },
    {
      "CurrencyCode": "VND",
      "Name": "Dong",
      "Symbol": "₫"
    },
    {
      "CurrencyCode": "VUV",
      "Name": "Vatu",
      "Symbol": ""
    },
    {
      "CurrencyCode": "WST",
      "Name": "Tala",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XAF",
      "Name": "CFA Franc BEAC",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XAG",
      "Name": "Silver",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XAU",
      "Name": "Gold",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XBA",
      "Name": "Bond Markets Units European Composite Unit (EURCO)",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XBB",
      "Name": "European Monetary Unit (E.M.U.-6)",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XBC",
      "Name": "European Unit of Account 9(E.U.A.-9)",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XBD",
      "Name": "European Unit of Account 17(E.U.A.-17)",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XCD",
      "Name": "East Caribbean Dollar",
      "Symbol": "$"
    },
    {
      "CurrencyCode": "XDR",
      "Name": "SDR",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XFU",
      "Name": "UIC-Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XOF",
      "Name": "CFA Franc BCEAO",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XPD",
      "Name": "Palladium",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XPF",
      "Name": "CFP Franc",
      "Symbol": ""
    },
    {
      "CurrencyCode": "XPT",
      "Name": "Platinum",
      "Symbol": ""
    },
    {
      "CurrencyCode": "YER",
      "Name": "Yemeni Rial",
      "Symbol": "﷼"
    },
    {
      "CurrencyCode": "ZAR",
      "Name": "Rand",
      "Symbol": "R"
    },
    {
      "CurrencyCode": "ZAR LSL",
      "Name": "Rand Loti",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ZAR NAD",
      "Name": "Rand Namibia Dollar",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ZMK",
      "Name": "Zambian Kwacha",
      "Symbol": ""
    },
    {
      "CurrencyCode": "ZWL",
      "Name": "Zimbabwe Dollar",
      "Symbol": ""
    }
  ]