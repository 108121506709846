import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { IMortgageCalculatorInput } from 'src/models';
import { getMortgageSummary } from 'src/utils/finance/mortgageUtils';
import SummaryView from 'src/components/common/SummaryView';

export default function MorgageCalculatorSummary() {

    const calculatorInputs: IMortgageCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.mortgagecalculatorInput);

    const [result, setResult] = React.useState<any>({});

    React.useEffect(() => {
        const result = getMortgageSummary(calculatorInputs);
        setResult(result || []);
    }, [calculatorInputs]);

    return <SummaryView summary={result} />;
};