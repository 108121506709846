import React from 'react';
import { View, Text, Input, Box, Button, FormControl, InputGroup, InputLeftAddon, InputRightAddon, Actionsheet, useDisclose, Center, Slider } from 'native-base';
import { FlatList, SafeAreaView, StyleSheet, ScrollView } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setTipCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IStore } from 'src/redux/reducers';
import { ITipCalculatorInput } from 'src/models/TipCalculatorInput';
import TipCalculatorSummary from '../Summary';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import PercentInput from 'src/components/common/PercentInput';
import ValueInput from 'src/components/common/ValueInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export default function TipCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const tipCalculatorInput: ITipCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.tipCalculatorInput);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === tipCalculatorInput?.currency)?.Symbol || "$";

    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(tipCalculatorInput?.currency || "USD");

    const [billAmount, setBillAmount] = React.useState(`${tipCalculatorInput?.billAmount || 100}`);
    const [tipPercent, setTipPercent] = React.useState(`${tipCalculatorInput?.tipPercent || 15}`);
    const [numberOfPeople, setNumberOfPeople] = React.useState(`${tipCalculatorInput?.numberOfPeople || 1}`);

    const [loading, setLoading] = React.useState(false);

    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setTipCalculatorInput({
            currency: currencyCode,
            billAmount: parseFloat(billAmount) || 0,
            tipPercent: parseFloat(tipPercent) || 0,
            numberOfPeople: parseInt(numberOfPeople) || 1
        }));

        setLoading(false);
    }

    return (
        <>
            <View style={styles.container}>
                <SafeAreaView style={styles.listContainer}>
                    <KeyboardAwareScrollView>
                        <Center>
                            <Box w="95%">
                                <FormControl>
                                    <AmountWithCurrencyInput label={"Bill Amount"}
                                        initialAmount={billAmount}
                                        onAmountChange={(newAmount: string) => {
                                            setBillAmount(newAmount)
                                        }}
                                        initialCurrency={currencyCode}
                                        onCurrencyChange={(newCurrency: any) => {
                                            setCurrencyDisplay(newCurrency.Symbol);
                                            setCurrencyCode(newCurrency.CurrencyCode);
                                        }} />
                                    <PercentInput label={"Tip Percent"}
                                        initialValue={tipPercent}
                                        sliderVisible={true}
                                        onValueChange={(newValue: string) => {
                                            setTipPercent(newValue);
                                        }} />

                                    <ValueInput label={"Number of People"}
                                        initialValue={numberOfPeople}
                                        sliderVisible={true}
                                        maxSliderValue={20}
                                        onValueChange={(newValue: string) => {
                                            setNumberOfPeople(newValue);
                                        }} />
                                </FormControl>

                                <Button
                                    style={styles.inputStyle}
                                    my={4}
                                    isLoading={loading}
                                    isLoadingText='Calculating...'
                                    onPress={() => {
                                        setCalculatorInputs();
                                    }}>Calculate</Button>

                                <TipCalculatorSummary />
                            </Box>
                        </Center>
                    </KeyboardAwareScrollView>
                </SafeAreaView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});