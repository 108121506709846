import FetchError from "../../../api/errors";

export const showErrorMessage = (error: FetchError | Error) => {
    
    if (error) {
        if ((error as FetchError).type === "existing-user") {
            console.log("You have an existing account - please log in.");
        } else if ((error as FetchError).type === 401) {
            console.log("Incorrect username/password.");
        } else if ((error as FetchError).type === 500) {
            console.log("There was an error. Please try again in a few seconds.");
        }
    }
};

export const showSuccessMessage = (text?: string) => {
    if (text) {
        console.log(text);
    }
};