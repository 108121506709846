import { IMortgageCalculatorInput } from 'src/models';
import { months } from '../../data/monthData';
import { formatCurrency } from '../formatUtils';
import { DEFAULT_CURRENCY } from 'src/constants';

const getMortgageSummary = (calculatorInputs: IMortgageCalculatorInput | undefined) => {
    const downPaymentAmount = getDownpaymentAmount(calculatorInputs?.downPaymentType || '',
        calculatorInputs?.downPayment || 0,
        calculatorInputs?.homePrice || 0,
        calculatorInputs?.currency || DEFAULT_CURRENCY);

    const monthlyPayment = calculateMonthlyMortgageAmount(calculatorInputs?.homePrice || 0,
        calculatorInputs?.interestRate || 0,
        calculatorInputs?.loanTerm || 0,
        downPaymentAmount);

    const payoffDate = calculateMortgagePayoffDate(calculatorInputs?.homePrice || 0,
        calculatorInputs?.interestRate || 0,
        calculatorInputs?.loanTerm || 0,
        calculatorInputs?.selectedMonth || 0,
        calculatorInputs?.selectedYear || 0,
        downPaymentAmount);

    const totalPayment = monthlyPayment * (calculatorInputs?.loanTerm || 0) * 12;
    const loanAmount = (calculatorInputs?.homePrice || 0) - downPaymentAmount;
    const totalInterest = totalPayment - loanAmount;
    

    return {
        "summary": [
            ['Loan Amount', formatCurrency(loanAmount, calculatorInputs?.currency || DEFAULT_CURRENCY)],
            ['Monthly Payment', formatCurrency(monthlyPayment, calculatorInputs?.currency || DEFAULT_CURRENCY)],
            ['Payoff Date', `${payoffDate}`],
            ['Total Payment', formatCurrency(totalPayment || 0, calculatorInputs?.currency || DEFAULT_CURRENCY)],
            ['Total Interest', formatCurrency(totalInterest || 0, calculatorInputs?.currency || DEFAULT_CURRENCY)]
        ],
        "pieChartData": [
            {
                label: 'Principal',
                svg: { fill: '#18c983' },
                value: loanAmount,
                percent: ((loanAmount) / totalPayment) * 100
            },
            {
                label: 'Interest',
                svg: { fill: '#db692c' },
                value: totalInterest || 0,
                percent: ((totalInterest || 0) / totalPayment) * 100
            }
        ]
    }
}

const getMortgageSchedule = (calculatorInputs: IMortgageCalculatorInput | undefined, scheduleType: string) => {
    const downPaymentAmount = getDownpaymentAmount(calculatorInputs?.downPaymentType || '',
        calculatorInputs?.downPayment || 0,
        calculatorInputs?.homePrice || 0,
        calculatorInputs?.currency || DEFAULT_CURRENCY);

    const currentDate = new Date();

    if (scheduleType === 'Monthly') {
        const monthlyAmortizationScheduleCsv = generateMonthlyAmortizationScheduleCsv(downPaymentAmount,
            calculatorInputs?.interestRate || 0,
            calculatorInputs?.homePrice || 0,
            calculatorInputs?.loanTerm || 0,
            calculatorInputs?.currency || DEFAULT_CURRENCY,
            calculatorInputs?.selectedMonth || currentDate.getMonth() + 1,
            calculatorInputs?.selectedYear || currentDate.getFullYear());
        return {
            "mortgageHeader": ['Month', 'Payment', 'Interest', 'Principal', 'Balance'],
            "mortgageSchedule": monthlyAmortizationScheduleCsv,
            "numberOfItemsPerPage": 12,
        }
    } else {
        const annualAmortizationScheduleCsv = generateAnnualAmortizationScheduleCsv(downPaymentAmount,
            calculatorInputs?.interestRate || 0,
            calculatorInputs?.homePrice || 0,
            calculatorInputs?.loanTerm || 0,
            calculatorInputs?.currency || DEFAULT_CURRENCY,
            calculatorInputs?.selectedMonth || currentDate.getMonth() + 1,
            calculatorInputs?.selectedYear || currentDate.getFullYear());
        return {
            "mortgageHeader": ['Year', 'Payment', 'Interest', 'Principal', 'Balance'],
            "mortgageSchedule": annualAmortizationScheduleCsv,
            "numberOfItemsPerPage": 10,
        }
    }
}

const getDownpaymentAmount = (downPaymentType: string,
    downPayment: number,
    homePrice: number,
    currency: string): number => {
    if (downPaymentType === "%") {
        return (downPayment / 100) * homePrice;
    } else {
        return downPayment;
    }
}

const calculateMonthlyMortgageAmount = (homePrice: number,
    interestRate: number,
    loanTerm: number,
    downpaymentAmount: number) => {
    const monthlyInterestRate = (interestRate / 100) / 12;
    const monthlyPayment = (homePrice - downpaymentAmount) * (monthlyInterestRate * Math.pow((1 + monthlyInterestRate), (loanTerm * 12))) / (Math.pow((1 + monthlyInterestRate), (loanTerm * 12)) - 1);
    return monthlyPayment;
}

const calculateMortgagePayoffDate = (homePrice: number,
    interestRate: number,
    loanTerm: number,
    selectedMonth: number,
    selectedYear: number,
    downpaymentAmount: number) => {
    const monthlyInterestRate = (interestRate / 100) / 12;
    const monthlyPayment = (homePrice - downpaymentAmount) * (monthlyInterestRate * Math.pow((1 + monthlyInterestRate), (loanTerm * 12))) / (Math.pow((1 + monthlyInterestRate), (loanTerm * 12)) - 1);
    const payoffDate = new Date(selectedYear, selectedMonth - 1, 1);
    payoffDate.setMonth(payoffDate.getMonth() + loanTerm * 12);

    //payoff date in format of MMMM yyyy
    const monthName = months.filter(month => parseInt(month.value) === payoffDate.getMonth() + 1)[0].label;
    return `${monthName} ${payoffDate.getFullYear()}`;
}

const generateAnnualAmortizationScheduleCsv = (downPaymentAmount: number,
    interestRate: number,
    homePrice: number,
    loanTerm: number,
    currency: string,
    startMonth: number,
    startYear: number) => {
    const monthlyInterestRate = (interestRate / 100) / 12;
    const monthlyPayment = (homePrice - downPaymentAmount) * (monthlyInterestRate * Math.pow((1 + monthlyInterestRate), (loanTerm * 12))) / (Math.pow((1 + monthlyInterestRate), (loanTerm * 12)) - 1);

    let annualAmortizationScheduleCsv = [];
    let annualInterest = 0;
    let annualPrincipal = 0;
    let annualBalance = homePrice - downPaymentAmount;
    let annualPayment = 0;

    for (let year = 1; year <= loanTerm; year++) {
        annualInterest = 0;
        annualPrincipal = 0;
        annualPayment = 0;

        for (let month = 1; month <= 12; month++) {
            const monthlyInterest = annualBalance * monthlyInterestRate;
            const monthlyPrincipal = monthlyPayment - monthlyInterest;
            annualInterest += monthlyInterest;
            annualPrincipal += monthlyPrincipal;
            annualBalance -= monthlyPrincipal;
            annualPayment += monthlyPayment;
        }

        const monthAndYear = new Date(startYear, startMonth - 1, 1);
        monthAndYear.setFullYear(monthAndYear.getFullYear() + year);

        annualAmortizationScheduleCsv.push([monthAndYear.getFullYear(),
        formatCurrency(annualPayment, currency),
        formatCurrency(annualInterest, currency),
        formatCurrency(annualPrincipal, currency),
        formatCurrency(annualBalance, currency)]);

    }

    return annualAmortizationScheduleCsv;
}

const generateMonthlyAmortizationScheduleCsv = (downPaymentAmount: number,
    interestRate: number,
    homePrice: number,
    loanTerm: number,
    currency: string,
    startMonth: number,
    startYear: number) => {
    const monthlyInterestRate = (interestRate / 100) / 12;
    const monthlyPayment = (homePrice - downPaymentAmount) * (monthlyInterestRate * Math.pow((1 + monthlyInterestRate), (loanTerm * 12))) / (Math.pow((1 + monthlyInterestRate), (loanTerm * 12)) - 1);

    let monthlyAmortizationScheduleCsv = [];
    let monthlyInterest = 0;
    let monthlyPrincipal = 0;
    let monthlyBalance = homePrice - downPaymentAmount;

    for (let month = 1; month <= loanTerm * 12; month++) {
        const monthAndYear = new Date(startYear, startMonth - 1, 1);
        monthAndYear.setMonth(monthAndYear.getMonth() + month);

        monthlyInterest = monthlyBalance * monthlyInterestRate;
        monthlyPrincipal = monthlyPayment - monthlyInterest;
        monthlyBalance -= monthlyPrincipal;

        const monthName = months.filter(month => parseInt(month.value) === monthAndYear.getMonth() + 1)[0].shortName;
        monthlyAmortizationScheduleCsv.push([
            `${monthName} ${monthAndYear.getFullYear()}`,
            formatCurrency(monthlyPayment, currency),
            formatCurrency(monthlyInterest, currency),
            formatCurrency(monthlyPrincipal, currency),
            formatCurrency(monthlyBalance, currency)]);
    }

    return monthlyAmortizationScheduleCsv;
}

export { getMortgageSummary, 
    getMortgageSchedule,
    getDownpaymentAmount,
    calculateMonthlyMortgageAmount,
 };