import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { IAgeCalculatorInput } from 'src/models';
import { ageCalculator } from 'src/utils/date/dateUtils';
import SummaryView from 'src/components/common/SummaryView';

export default function AgeCalculatorSummary() {

    const calculatorInputs: IAgeCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.ageCalculatorInput);

    const [result, setResult] = React.useState<any>({});

    React.useEffect(() => {
        const result = ageCalculator(calculatorInputs);
        setResult(result || []);
    }, [calculatorInputs]);

    return <SummaryView summary={result} />;
};