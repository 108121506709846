import * as Effects from "redux-saga/effects";

import * as PaymentAction from "../actions/paymentAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";

const call: any = Effects.call;
const put: any = Effects.put;
const takeEvery: any = Effects.takeEvery;
const all: any = Effects.all;
const fork: any = Effects.fork;

export function* checkoutSession(action: ReturnType<typeof PaymentAction.checkoutSession.request>): any {
  try {
    console.log('registering device token', action.payload);
    const response = yield call(api.createPaymentSession, action.payload);
    yield put(PaymentAction.checkoutSession.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(PaymentAction.checkoutSession.failure(error));
  }
}
/*
 * WATCHERS
 */

export function* watchCheckoutSession() {
  yield takeEvery(PaymentAction.checkoutSession.request, checkoutSession);
}

export default function* root() {
  yield all([fork(watchCheckoutSession)]);
}