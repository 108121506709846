import { all, call, delay, fork, put, race, take, takeEvery, takeLatest } from "redux-saga/effects";

import * as JobActions from "../actions/jobAction";
import * as api from "../../api/inferenceJob";
import { showErrorMessage } from "./shared";
import { IJob, IJobRequest, IJobs } from "../../models";

// Name of action which ends the polling
const CANCEL_STATUS_POLLING = "CancelJobStatusPolling"
const CANCEL_USER_JOBS_POLLING = "CancelUserJobsPolling"
// Time between polling iterations in MS.
const POLLING_DELAY = 10000;

export function* createJob(action: ReturnType<typeof JobActions.createJob.request>): any {
  try {
    const createRequest: IJobRequest = action.payload
    console.log('invoked create job', action.payload)
    const response = yield call(api.createJob, action.payload);
    yield put(JobActions.createJob.success(response));

    yield put(JobActions.queueJob.request(action.payload.jobId));
    yield put(JobActions.pollJobStatus.request(action.payload.jobId));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.createJob.failure(error));
  }
}

export function* createRealtimeJob(action: ReturnType<typeof JobActions.createRealtimeJob.request>): any {
  try {
    const createRequest: IJobRequest = action.payload
    console.log('invoked create job', action.payload)
    const createJobResponse = yield call(api.createJob, action.payload);
    
    const inferenceResponse = yield call(api.realtimeInferenceJob, createRequest.jobId);
    yield put(JobActions.createRealtimeJob.success(inferenceResponse));
    yield put(JobActions.jobStatus.success(inferenceResponse));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.createRealtimeJob.failure(error));
  }
}

export function* queueJob(action: ReturnType<typeof JobActions.queueJob.request>): any {
  try {
    const queueResponse = yield call(api.queueJob, action.payload);
    yield put(JobActions.queueJob.success(queueResponse));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.createJob.failure(error));
  }
}

export function* getJob(action: ReturnType<typeof JobActions.jobStatus.request>): any {
  try {
    const response = yield call(api.getJob, action.payload);
    yield put(JobActions.jobStatus.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.jobStatus.failure(error));
  }
}

export function* getJobs(action: ReturnType<typeof JobActions.userJobs.request>): any {
  try {
    const response = yield call(api.getJobs, action.payload);
    yield put(JobActions.userJobs.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(JobActions.userJobs.failure(error));
  }
}

export function* jobStatusWatchWorker(action: ReturnType<typeof JobActions.pollJobStatus.request>) {
  yield put({ type: CANCEL_STATUS_POLLING })
  // Race starts two concurrent effects. We start our polling effect 'task'. As soon
  // as the take effect 'cancelled' is triggered, the 'task' will be cancelled.
  yield race({
    //4. Start the polling worker
    task: call(jobStatusPollingWorker, action),
    //5. Start a take effect waiting for the cancel action.
    cancel: take(CANCEL_STATUS_POLLING)
  })
}

export function* jobStatusPollingWorker(action: ReturnType<typeof JobActions.pollJobStatus.request>) {
  while (true) {
    try {
      console.log('invoked get job', action.payload)
      const response: IJob = yield call(api.getJob, action.payload);
      yield put(JobActions.pollJobStatus.success(response));

      if (response.jobStatus !== "FINISHED") {
        yield delay(POLLING_DELAY);
      } else {
        yield put({ type: CANCEL_STATUS_POLLING })
      }
    } catch (error: any) {
      showErrorMessage(error);
      yield put({ type: CANCEL_STATUS_POLLING })
    }
  }
}

export function* userJobsWatchWorker(action: ReturnType<typeof JobActions.pollUserJobs.request>) {
  yield put({ type: CANCEL_USER_JOBS_POLLING })
  // Race starts two concurrent effects. We start our polling effect 'task'. As soon
  // as the take effect 'cancelled' is triggered, the 'task' will be cancelled.
  yield race({
    //4. Start the polling worker
    task: call(userJobsPollingWorker, action),
    //5. Start a take effect waiting for the cancel action.
    cancel: take(CANCEL_USER_JOBS_POLLING)
  })
}

export function* userJobsPollingWorker(action: ReturnType<typeof JobActions.pollUserJobs.request>) {
  while (true) {
    try {
      console.log('invoked get user jobs', action.payload)
      const response: IJobs = yield call(api.getJobs, action.payload);
      yield put(JobActions.pollUserJobs.success(response));
      yield delay(POLLING_DELAY);
    } catch (error: any) {
      showErrorMessage(error);
      yield put({ type: CANCEL_USER_JOBS_POLLING })
    }
  }
}

/*
 * WATCHERS
 */

export function* watchGetJobPolling() {
  yield takeEvery(JobActions.pollJobStatus.request, jobStatusWatchWorker);
}

// not watching currently
export function* watchUserJobsPolling() {
  yield takeEvery(JobActions.pollUserJobs.request, userJobsWatchWorker);
}

export function* watchCreateJob() {
  yield takeEvery(JobActions.createJob.request, createJob);
}

export function* watchCreateRealtimeJob() {
  yield takeEvery(JobActions.createRealtimeJob.request, createRealtimeJob);
}

export function* watchQueueJob() {
  yield takeEvery(JobActions.queueJob.request, queueJob);
}

export function* watchGetJob() {
  yield takeEvery(JobActions.jobStatus.request, getJob);
}

export function* watchGetUserJobs() {
  yield takeEvery(JobActions.userJobs.request, getJobs);
}

export default function* root() {
  yield all([fork(watchCreateJob),
    fork(watchCreateRealtimeJob),
  fork(watchQueueJob),
  fork(watchGetJob),
  fork(watchGetJobPolling),
  fork(watchGetUserJobs)]);
}