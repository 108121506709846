import React from 'react';

import { Text, FormControl, Input, InputGroup, Slider, InputRightAddon } from 'native-base';

export default function PercentInput({
    label,
    initialValue,
    sliderVisible,
    onValueChange }) {

    const [value, setValue] = React.useState(`${initialValue || 0}`);

    return (<>
        <FormControl.Label>{label}</FormControl.Label>
        <InputGroup>
            <Input w={"85%"} keyboardType='decimal-pad'
                value={value}
                onChangeText={(text) => {
                    setValue(text);
                    onValueChange(text);
                }}
                placeholder={label} />
            <InputRightAddon w={"15%"} children={
                <Text minWidth={25}
                    textAlign={"center"}
                    justifyContent={"center"}
                    fontSize="md" color="gray.500">%</Text>
            } />
        </InputGroup>
        {
            sliderVisible &&
            <Slider
                defaultValue={parseFloat(value) || 0}
                value={parseFloat(value) || 0}
                minValue={0}
                maxValue={100}
                my={3}
                onChange={(value) => {
                    setValue(value.toString());
                    onValueChange(value.toString());
                }}
                accessibilityLabel="Percent" step={1}>
                <Slider.Track shadow={2}>
                    <Slider.FilledTrack />
                </Slider.Track>
                <Slider.Thumb shadow={3} />
            </Slider>
        }
    </>);
};