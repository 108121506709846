import React from 'react'
import { LineChart, Grid, XAxis, YAxis } from 'react-native-svg-charts'
import * as shape from 'd3-shape'
import { Circle, G, Line, Rect, Text } from 'react-native-svg'
import { View } from 'react-native'

class LineChartWithTooltip extends React.PureComponent {

    render() {

        /**
         * Both below functions should preferably be their own React Components
         */

        const axesSvg = { fontSize: 10, fill: 'grey' };
        const verticalContentInset = { top: 10, bottom: 10 }
        const xAxisHeight = 30

        const Tooltip = ({ x, y, value, index }) => (
            <G
                x={x(index) - (75 / 2)}
                key={'tooltip'}
                onPress={() => console.log('tooltip clicked')}
            >
                <G y={50}>
                    <Rect
                        height={40}
                        width={75}
                        stroke={'grey'}
                        fill={'white'}
                        ry={10}
                        rx={10}
                    />
                    <Text
                        x={75 / 2}
                        dy={20}
                        alignmentBaseline={'middle'}
                        textAnchor={'middle'}
                        stroke={'rgb(134, 65, 244)'}
                    >
                        {`${value}`}
                    </Text>
                </G>
                <G x={75 / 2}>
                    <Line
                        y1={50 + 40}
                        y2={y(value)}
                        stroke={'grey'}
                        strokeWidth={2}
                    />
                    <Circle
                        cy={y(value)}
                        r={6}
                        stroke={'rgb(134, 65, 244)'}
                        strokeWidth={2}
                        fill={'white'}
                    />
                </G>
            </G>
        )

        return (
            <View style={{ height: 400, padding: 20, flexDirection: 'row' }}>
                <YAxis
                    data={this.props.data}
                    style={{ marginBottom: xAxisHeight }}
                    contentInset={verticalContentInset}
                    svg={axesSvg}
                />
                <View style={{ flex: 1, marginLeft: 10 }}>
                    <LineChart
                        style={{ flex: 1 }}
                        data={this.props.data}
                        svg={{
                            stroke: 'rgb(134, 65, 244)',
                            strokeWidth: 2,
                        }}
                        contentInset={verticalContentInset}
                        curve={shape.curveLinear}
                    >
                        <Grid />
                        {this.props.data.map((value, index) => {
                            return (
                                <Tooltip
                                    key={index}
                                    index={index}
                                    value={`${value.toFixed(1)}`}
                                />
                            )
                        })}
                    </LineChart>
                    <XAxis
                        style={{ marginHorizontal: -10, height: xAxisHeight }}
                        data={this.props.data}
                        formatLabel={(value, index) => index}
                        contentInset={{ left: 10, right: 10 }}
                        svg={axesSvg}
                    />
                </View>
            </View>
        )
    }

}

export default LineChartWithTooltip