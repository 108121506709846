import { formatCurrency } from '../formatUtils';
import { DEFAULT_CURRENCY } from 'src/constants';
import { IRealEstateROICalculatorInput } from 'src/models/RealEstateROICalculatorInput';
import { calculateMonthlyMortgageAmount, getDownpaymentAmount } from './mortgageUtils';
import { ICalculatorInput } from 'src/models';

function calculateRealEstateROI(input: ICalculatorInput | undefined): any {
    if (!input) {
        return {
            "summary": [],
            "pieChartData": []
        };
    }

    const roiCalculatorInput = input as IRealEstateROICalculatorInput

    const {
        currency,
        purchasePrice,
        downPayment,
        downPaymentType,
        interestRate,
        loanTerm,
        closingCosts,
        monthlyRent,
        otherMonthlyIncome,
        propertyTaxes,
        insurance,
        hoaFees,
        vacancyRate,
        propertyManagementFees,
        repairAndMaintenance,
        sellingCosts,
        yearsBeforeSale,
        annualAppreciationRate,
        annualRentIncreaseRate,
    } = roiCalculatorInput;

    // Calculate monthly expenses
    const monthlyExpenses =
        propertyTaxes / 12 +
        insurance / 12 +
        hoaFees +
        propertyManagementFees +
        repairAndMaintenance / 12;

    const downPaymentAmount = getDownpaymentAmount(downPaymentType || '',
        downPayment || 0,
        purchasePrice || 0,
        currency || DEFAULT_CURRENCY);

    const monthlyPayment = calculateMonthlyMortgageAmount(purchasePrice || 0,
        interestRate || 0,
        loanTerm || 0,
        downPaymentAmount);

    // Calculate monthly cash flow
    const monthlyCashFlow = monthlyRent + otherMonthlyIncome - monthlyExpenses;

    // Calculate loan amount
    const loanAmount = purchasePrice - downPaymentAmount;

    // Calculate annual cash flow
    const annualCashFlow = monthlyCashFlow * 12;

    // Calculate equity accumulation through loan payments
    const loanPayments = calculateLoanPayments(
        monthlyPayment,
        loanAmount,
        interestRate,
        loanTerm
    );
    const equityAccumulation = loanPayments.reduce(
        (total, payment) => total + payment.principal,
        0
    );

    // Calculate property appreciation
    const propertyValueAfterYears =
        purchasePrice * Math.pow(1 + annualAppreciationRate, yearsBeforeSale);

    // Calculate selling costs
    const sellingCostAmount = propertyValueAfterYears * (sellingCosts / 100);

    // Calculate total return
    const totalReturn = annualCashFlow * yearsBeforeSale + equityAccumulation;

    // Calculate ROI
    const totalInvestment = downPaymentAmount + closingCosts + repairAndMaintenance;
    const netProfit = totalReturn - totalInvestment - sellingCostAmount;
    const roi = (netProfit / totalInvestment) * 100;

    return {
        "summary": [
            ['Total Investment', formatCurrency(totalInvestment, currency || DEFAULT_CURRENCY)],
            ['Total Return', formatCurrency(totalReturn, currency || DEFAULT_CURRENCY)],
            ['Net Profit', formatCurrency(netProfit, currency || DEFAULT_CURRENCY)],
            ['ROI', `${roi.toFixed(2)}%`]
        ],
        "pieChartData": [
            {
                label: 'Cash Flow',
                svg: { fill: '#18c983' },
                value: annualCashFlow,
                percent: (annualCashFlow / totalReturn) * 100
            },
            {
                label: 'Equity Accumulation',
                svg: { fill: '#db692c' },
                value: equityAccumulation,
                percent: (equityAccumulation / totalReturn) * 100
            },
            {
                label: 'Property Appreciation',
                svg: { fill: '#db692c' },
                value: propertyValueAfterYears,
                percent: (propertyValueAfterYears / totalReturn) * 100
            }
        ]
    }
}

// Helper function to calculate loan payments
function calculateLoanPayments(
    monthlyPayment: number,
    loanAmount: number,
    interestRate: number,
    loanTerm: number
): { principal: number; interest: number }[] {
    const payments: { principal: number; interest: number }[] = [];
    const monthlyInterestRate = interestRate / 12 / 100;
    const numPayments = loanTerm * 12;
    let remainingPrincipal = loanAmount;

    for (let i = 0; i < numPayments; i++) {
        const interestPayment = remainingPrincipal * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
        remainingPrincipal -= principalPayment;
        payments.push({ principal: principalPayment, interest: interestPayment });
    }

    return payments;
}

export { calculateRealEstateROI };