import React from 'react';
import { StyleSheet } from 'react-native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { APP_THEME } from 'src/constants';
import InvestmentCalculatorInput from '../Input';
import InvestmentCalculatorSummary from '../Summary';
const Tab = createMaterialTopTabNavigator();


export default function InvestmentCalculatorTabs() {
    return <>
        <Tab.Navigator
            activeColor="white"
            inactiveColor="#E0E0E0"
            tabBarOptions={{
                labelStyle: { textTransform: "none", fontSize: 8 }
            }}
            barStyle={{ backgroundColor: APP_THEME.primaryColor }}>
            <Tab.Screen name="Investment Calculator" component={InvestmentCalculatorInput} options={{
                tabBarLabel: 'Calculator',
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="information" color={color} size={24} />
                ),
            }} />
            <Tab.Screen name="Investment Summary" component={InvestmentCalculatorSummary} options={{
                tabBarLabel: 'Summary',
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="cash" color={color} size={24} />
                ),
            }} />
        </Tab.Navigator>
    </>;
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        margin: 4,
    },
    cardStyle: { margin: 4 }
});