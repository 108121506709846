import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { ITipCalculatorInput } from 'src/models/TipCalculatorInput';
import { tipCalculator } from 'src/utils/finance/tipUtils';
import SummaryView from 'src/components/common/SummaryView';

export default function TipCalculatorSummary() {

    const calculatorInputs: ITipCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.tipCalculatorInput);

    const [result, setResult] = React.useState<any>({});

    React.useEffect(() => {
        const result = tipCalculator(calculatorInputs);
        setResult(result || []);
    }, [calculatorInputs]);

    return <SummaryView summary={result} />;
};