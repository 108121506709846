import { ActionType, getType } from "typesafe-actions";
import { IGrainTypes } from "../../models";
import * as actions from "../actions/notificationAction";

export interface INotificationState {
    readonly deviceToken?: string;
    readonly deviceId?: string;
    readonly registerToken: IRegisterTokenState;
}

export interface IRegisterTokenState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly status: Boolean;
}

export const initialNotificationState: INotificationState = {
    deviceId: undefined,
    deviceToken: undefined,
    registerToken: {
        error: undefined,
        isFetching: false,
        status: false
    }
};

export const NotificationReducer = (
    state: INotificationState = initialNotificationState,
    action: ActionType<typeof actions>
): INotificationState => {
    switch (action.type) {
        case getType(actions.setDeviceToken):
            return {
                ...state,
                deviceToken: action.payload
            };
        case getType(actions.setDeviceId):
            return {
                ...state,
                deviceId: action.payload
            };
        case getType(actions.registerToken.request):
            return {
                ...state,
                registerToken: {
                    ...state.registerToken,
                    isFetching: true,
                }
            };
        case getType(actions.registerToken.success):
            return {
                ...state,
                registerToken: {
                    error: undefined,
                    isFetching: false,
                    status: action.payload
                }
            };
        case getType(actions.registerToken.failure):
            return {
                ...state,
                registerToken: {
                    error: action.payload,
                    isFetching: false,
                    status: false
                }
            };
        default:
            return state;
    }
};