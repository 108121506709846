import React from 'react';
import { View, Box, FormControl, CheckIcon, Select, Center } from 'native-base';
import { SafeAreaView, StyleSheet, ScrollView } from 'react-native';
import DisplayCsvDataTable from '../../../../DisplayCsvDataTable';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { IMortgageCalculatorInput } from 'src/models';
import { getMortgageSchedule } from 'src/utils/finance/mortgageUtils';
import SelectWithLabel from 'src/components/common/SelectWithLabel';

export default function MorgageCalculatorSchedule() {

    const calculatorInputs: IMortgageCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.mortgagecalculatorInput);

    const [scheduleType, setScheduleType] = React.useState<string>('Monthly');
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState<number>(12);

    const [morgageSummaryHeader, setMorgageSummaryHeader] = React.useState<any[]>(['Year', 'Payment', 'Interest', 'Principal', 'Balance']);
    const [morgageSummary, setMorgageSummary] = React.useState<any[]>([]);

    React.useEffect(() => {
        const result = getMortgageSchedule(calculatorInputs, scheduleType);

        setMorgageSummaryHeader(result["mortgageHeader"]);
        setNumberOfItemsPerPage(result["numberOfItemsPerPage"]);
        setMorgageSummary(result["mortgageSchedule"]);
    }, [calculatorInputs, scheduleType]);

    return (
        <>
            <View style={styles.container}>
                <SafeAreaView style={styles.listContainer}>
                    <ScrollView>
                        <Center>
                            <Box w="95%">
                                <FormControl>
                                    <SelectWithLabel
                                        label={"Amortization Schedule"}
                                        allOptions={[{ label: "Monthly", value: "Monthly" },
                                        { label: "Annual", value: "Annual" }]}
                                        initialValue={"Monthly"}
                                        onValueChange={(itemValue: string) => setScheduleType(itemValue)}
                                    />
                                </FormControl>
                                {morgageSummary.length > 0 &&
                                    <DisplayCsvDataTable
                                        tableHead={morgageSummaryHeader}
                                        tableData={morgageSummary}
                                        showPagination={true}
                                        numberOfPages={morgageSummary.length / numberOfItemsPerPage}
                                        numItemsPerPage={numberOfItemsPerPage} />}
                            </Box>
                        </Center>
                    </ScrollView>
                </SafeAreaView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
        flex: 1,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        margin: 4,
    },
    cardStyle: { margin: 4 }
});