import { Auth as Authorization } from 'aws-amplify';
import { API_BASE_URL } from '../../constants'

export const getAuthHeaders = async (): Promise<Headers | Record<string, string> | string[][]> => {
    const authToken = await getToken();

    return authToken
        ? {
            "Authorization": `Bearer ${authToken}`,
            "Content-Type": "application/json",
            "Accept": "*/*"
        }
        : {};
};

const getToken = async (): Promise<string> => {
    const session: any = await Authorization.currentSession();

    const token = session.accessToken.jwtToken
    return token
}

export const customFetch = async (
    input: RequestInfo,
    init?: RequestInit | undefined
): Promise<Response> => {

    const response = await fetch(`${API_BASE_URL}${input}/`, {
        ...init,
        headers: {
            ...init?.headers,
            ...await getAuthHeaders(),
            ...(init &&
                (init.method === "PUT" || init.method === "POST") && {
                "Content-Type": "application/json"
            })
        }
    });

    return response;
};