import { createAsyncAction } from "typesafe-actions";
import { IUploadJobInputRequest, IUploadRequest, IGetPresignedUrlRequest, ISignedUrlMapResponse } from "../../models";

export const putPresignedUrl = createAsyncAction(
    "upload/PUT_PRESIGNED_URL_REQUEST",
    "upload/PUT_PRESIGNED_URL_SUCCESS",
    "upload/PUT_PRESIGNED_URL_FAILURE"
)<IUploadJobInputRequest, ISignedUrlMapResponse, Error>();

export const getPresignedUrl = createAsyncAction(
    "upload/GET_PRESIGNED_URL_REQUEST",
    "upload/GET_PRESIGNED_URL_SUCCESS",
    "upload/GET_PRESIGNED_URL_FAILURE"
)<IGetPresignedUrlRequest, ISignedUrlMapResponse, Error>();

export const uploadUsingPresignedUrl = createAsyncAction(
    "upload/UPLOAD_REQUEST",
    "upload/UPLOAD_SUCCESS",
    "upload/UPLOAD_FAILURE"
)<IUploadRequest, boolean, Error>();