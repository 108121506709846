import { ActionType, getType } from "typesafe-actions";
import { IUserSubscription } from "../../models";
import * as actions from "../actions/userAction";

export interface IUserState {
    subscriptionStatus: string;
    readonly userSubscription: IUserSubscriptionState;
}

export interface IUserSubscriptionState {
    readonly error?: Error;
    readonly isFetching: boolean;
    readonly subscriptions: IUserSubscription[];
}

export const initialUserState: IUserState = {
    subscriptionStatus: "ACTIVE",
    userSubscription: {
        error: undefined,
        isFetching: false,
        subscriptions: []
    }
};

export const UserReducer = (
    state: IUserState = initialUserState,
    action: ActionType<typeof actions>
): IUserState => {
    switch (action.type) {
        case getType(actions.setUserSubscriptionStatus):
            return {
                ...state,
                subscriptionStatus: action.payload
            };
        case getType(actions.checkUserSubscriptionStatus.request):
            return {
                ...state,
                userSubscription: {
                    ...state.userSubscription,
                    error: undefined,
                    isFetching: true
                }
            };
        case getType(actions.checkUserSubscriptionStatus.success):
            return {
                ...state,
                userSubscription: {
                    error: undefined,
                    isFetching: false,
                    subscriptions: action.payload
                }
            };
        case getType(actions.checkUserSubscriptionStatus.failure):
            return {
                ...state,
                userSubscription: {
                    ...state.userSubscription,
                    error: action.payload,
                    isFetching: false,
                }
            };
        case getType(actions.reset):
            return initialUserState;
        default:
            return state;
    }
};