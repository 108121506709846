import * as HttpStatus from "http-status-codes";


export type IFetchErrorType =
  | typeof HttpStatus.StatusCodes.UNAUTHORIZED
  | typeof HttpStatus.StatusCodes.NOT_FOUND
  | "existing-user"
  | typeof HttpStatus.StatusCodes.INTERNAL_SERVER_ERROR;

class FetchError extends Error {
  public name: "FetchError";
  public type: IFetchErrorType;

  constructor(message: any, type: IFetchErrorType) {
    super(message);
    this.name = "FetchError";
    this.type = type;
  }
}

export default FetchError;