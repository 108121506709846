import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/appAction";

export interface IAppActionState {
    username: string | undefined;
    showForceLogoutPopup: boolean;
}

export const initialAppState: IAppActionState = {
    username: undefined,
    showForceLogoutPopup: false
};

export const AppReducer = (
    state: IAppActionState = initialAppState,
    action: ActionType<typeof actions>
): IAppActionState => {
    switch (action.type) {
        case getType(actions.setUsername):
            return {
                ...state,
                username: action.payload
            };
        case getType(actions.forceLogout):
            return {
                ...state,
                showForceLogoutPopup: true
            };
        case getType(actions.forceLogoutHandled):
            return {
                ...state,
                showForceLogoutPopup: false
            };
        case getType(actions.reset):
            return initialAppState;
        default:
            return state;
    }
};