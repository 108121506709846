import { FormControl, Input } from 'native-base';
import React, { useState } from 'react';

import { View } from "react-native";
import { DatePickerModal } from 'react-native-paper-dates';

export const DatePickerInput = ({
    label,
    initialValue,
    onValueChange }) => {
    const initialDateValue = typeof initialValue === 'string' ? new Date(initialValue) : initialValue;

    const [date, setDate] = React.useState(initialDateValue);

    const [displayDate, setDisplayDate] = useState((initialDateValue || new Date()).toLocaleDateString());
    const [open, setOpen] = React.useState(false);

    const onDismissSingle = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setOpen(false);
            setDate(params.date);
            setDisplayDate(params.date.toLocaleDateString());
            onValueChange(params.date);
        },
        [setOpen, setDate]
    );

    return (
        <View>
            <FormControl.Label>{label}</FormControl.Label>
            <Input
                w={"100%"}
                value={displayDate}
                onFocus={() => setOpen(true)}
                keyboardType='decimal-pad'
                placeholder="Selected Date" />
            <DatePickerModal
                locale="en"
                mode="single"
                visible={open}
                onDismiss={onDismissSingle}
                date={date}
                onConfirm={onConfirmSingle}
            />
        </View>
    );
};