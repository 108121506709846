import React from 'react';
import { View, Box, Button, FormControl, Center, ScrollView } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { setDaysBetweenTwoDatesCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IDaysBetweeenTwoDatesCalculatorInput } from 'src/models';
import { IStore } from 'src/redux/reducers';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { DatePickerInput } from 'src/components/common/DatePickerInput';
import DaysBetweenTwoDatesCalculatorSummary from '../Summary';

export default function DaysBetweenTwoDatesCalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const calculatorInputs: IDaysBetweeenTwoDatesCalculatorInput | undefined = useSelector((store: IStore) => store.calculator.daysBetweenTwoDatesCalculatorInput);

    const [startDate, setStartDate] = React.useState(calculatorInputs?.startDate || new Date());
    const [endDate, setEndDate] = React.useState(calculatorInputs?.endDate || new Date());

    const [loading, setLoading] = React.useState(false);


    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setDaysBetweenTwoDatesCalculatorInput({
            startDate: startDate,
            endDate: endDate
        }));

        setLoading(false);
    }

    return (
        <>
            <ScrollView>
                <View style={styles.container}>
                    <SafeAreaView style={styles.listContainer}>
                        <KeyboardAwareScrollView>
                            <Center>
                                <Box w="95%">
                                    <FormControl>
                                        <DatePickerInput
                                            label="Start Date"
                                            initialValue={startDate}
                                            onValueChange={(value: Date) => setStartDate(value)}
                                        />

                                        <DatePickerInput
                                            label="End Date"
                                            initialValue={endDate}
                                            onValueChange={(value: Date) => setEndDate(value)}
                                        />

                                    </FormControl>

                                    <Button
                                        style={styles.inputStyle}
                                        my={4}
                                        shadow={2}
                                        isLoading={loading}
                                        isLoadingText='Calculating...'
                                        onPress={() => {
                                            setCalculatorInputs();
                                        }}>Calculate</Button>

                                    <DaysBetweenTwoDatesCalculatorSummary />
                                </Box>
                            </Center>
                        </KeyboardAwareScrollView>
                    </SafeAreaView>
                </View>
            </ScrollView>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});