import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import reduxPromiseMiddleware from "redux-promise-middleware";
import reduxSaga from "redux-saga";

import { persistStore } from 'redux-persist';

import { pReducer } from "./reducers";
import { rootSaga } from "./sagas";

const sagaMiddleware = reduxSaga();

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(reduxPromiseMiddleware, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export const persistedStore = persistStore(store);