import React from 'react';

import { Actionsheet, Text, Box, FormControl, Input, InputGroup, InputLeftAddon, useDisclose } from 'native-base';
import { FlatList } from 'react-native';
import { allCurrencies } from 'src/data/currencyData';

export default function AmountWithCurrencyInput({
    label,
    initialCurrency,
    onCurrencyChange,
    initialAmount,
    onAmountChange }) {

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === initialCurrency)?.Symbol || "$";

    const [amount, setAmount] = React.useState(`${initialAmount || 0}`);

    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(initialCurrency || "USD");

    const { isOpen: currencySheetOpen,
        onOpen: onCurrencySheetOpen,
        onClose: onCurrencySheetClose } = useDisclose();

    return (<>
        <Actionsheet isOpen={currencySheetOpen} onClose={onCurrencySheetClose}>
            <Actionsheet.Content>
                <Box w="100%" h={60} px={4} justifyContent="center">
                    <Text fontSize="16" color="gray.500" _dark={{
                        color: "gray.300"
                    }}>
                        Choose currency
                    </Text>
                </Box>
                <FlatList data={allCurrencies}
                    renderItem={({
                        item
                    }) => <Text
                        mx={4}
                        my={3}
                        onPress={() => {
                            setCurrencyDisplay(item.Symbol);
                            setCurrencyCode(item.CurrencyCode);
                            onCurrencyChange(item);
                            onCurrencySheetClose();
                        }}
                        fontSize="md" _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800" alignSelf="flex-start">
                            {item.Symbol === ""
                                ? `${item.Name}`
                                : `${item.Name} (${item.Symbol})`}
                        </Text>} keyExtractor={item => item.CurrencyCode} />
            </Actionsheet.Content>
        </Actionsheet>
        <FormControl.Label>{label}</FormControl.Label>
        <InputGroup>
            <InputLeftAddon w={"15%"} children={
                <Text minWidth={25}
                    textAlign={"center"}
                    justifyContent={"center"}
                    onPress={onCurrencySheetOpen} fontSize="md"
                    color="gray.500">{currencyDisplay}</Text>
            } />
            <Input w={"85%"}
                keyboardType='decimal-pad'
                value={amount}
                autoFocus={true}
                onChangeText={(text) => {
                    setAmount(text);
                    onAmountChange(text);
                }}
                placeholder={label}></Input>
        </InputGroup>
    </>);
};