import { createAction, createAsyncAction } from "typesafe-actions";
import { IJob, IJobRequest, IJobs } from "../../models";

export const setJobRequest = createAction('SET_JOB_REQUEST')<IJobRequest>();
export const setJobId = createAction('SET_JOB_ID')<string>();

export const createJob = createAsyncAction(
    "job/CREATE_JOB_REQUEST",
    "job/CREATE_JOB_SUCCESS",
    "job/CREATE_JOB_FAILURE"
)<IJobRequest, Boolean | null, Error>();

export const createRealtimeJob = createAsyncAction(
    "job/CREATE_REALTIME_JOB_REQUEST",
    "job/CREATE_REALTIME_JOB_SUCCESS",
    "job/CREATE_REALTIME_JOB_FAILURE"
)<IJobRequest, IJob | null, Error>();

export const queueJob = createAsyncAction(
    "job/QUEUE_JOB_REQUEST",
    "job/QUEUE_JOB_SUCCESS",
    "job/QUEUE_JOB_FAILURE"
)<string, Boolean | null, Error>();

export const userJobs = createAsyncAction(
    "job/USER_JOBS_REQUEST",
    "job/USER_JOBS_SUCCESS",
    "job/USER_JOBS_FAILURE"
)<string, IJobs | null, Error>();

export const jobStatus = createAsyncAction(
    "job/JOB_STATUS_REQUEST",
    "job/JOB_STATUS_SUCCESS",
    "job/JOB_STATUS_FAILURE"
)<string, IJob | null, Error>();

export const pollJobStatus = createAsyncAction(
    "job/POLL_JOB_STATUS_REQUEST",
    "job/POLL_JOB_STATUS_SUCCESS",
    "job/POLL_JOB_STATUS_FAILURE"
)<string, IJob | null, Error>();

export const pollUserJobs = createAsyncAction(
    "job/POLL_USER_JOBS_REQUEST",
    "job/POLL_USER_JOBS_SUCCESS",
    "job/POLL_USER_JOBS_FAILURE"
)<string, IJobs | null, Error>();