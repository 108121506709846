import FetchError from "./errors";
import { IPresignedUrlRequest, ISignedUrlMapResponse, IUploadObjectRequest } from "../models";
import { customFetch } from "./shared";

export const getPresignedUrl = async (uploadRequest: IPresignedUrlRequest): Promise<ISignedUrlMapResponse | null> => {
  try {
    console.log('upload request', uploadRequest)
    const response = await customFetch(`/upload`, {
      method: "POST",
      body: JSON.stringify(uploadRequest)
    })
    console.log('response', response)
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error fetching presigned url', e);
    throw new FetchError(e, 500);
  }
};

export const uploadUsingPresignedUrl = async (request: IUploadObjectRequest): Promise<boolean | null> => {
  try {
    const file = request.dataObject
    const imageBody = await getBlob(file.uri)
    const fileType = imageBody["type"]

    const response = await fetch(request.presignedUrl, {
      method: "PUT",
      body: imageBody,
      headers: { "Content-Type": fileType ?? "image/jpeg" }
    })
    if (response.ok) {
      return true
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    console.log('error uploading object', e);
    throw new FetchError(e, 500);
  }
};

const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};