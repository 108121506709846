import React from 'react';
import { View, Box, Center } from 'native-base';
import { SafeAreaView, StyleSheet, ScrollView } from 'react-native';
import DisplayCsvDataTable from '../../DisplayCsvDataTable';
import { PieChartWithLabels } from 'src/components/common/PieChartWithLabels';
import LineChartWithTooltip from '../LineChartWithTooltip';

export default function SummaryView({
    summary
}) {

    const [summaryTableData, setSummaryTableData] = React.useState<any[]>([]);
    const [pieChartData, setPieChartData] = React.useState<any[]>([]);
    const [lineChartData, setLineChartData] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (summary['summary'] != undefined) {
            setSummaryTableData(summary['summary'] || []);
        }
        if (summary['pieChartData'] != undefined) {
            setPieChartData(summary["pieChartData"] || []);
        }

        if (summary['lineChartData'] != undefined) {
            setLineChartData(summary["lineChartData"] || []);
        }
    }, [summary]);

    return (
        <>
            <View style={styles.container}>
                <SafeAreaView style={styles.listContainer}>
                    <ScrollView>
                        <Center>
                            <Box backgroundColor={"white"} w="95%">
                                {
                                    pieChartData.length > 0 &&
                                    <PieChartWithLabels data={pieChartData} />
                                }
                                {
                                    lineChartData.length > 0 &&
                                    <LineChartWithTooltip data={lineChartData} />
                                }
                                {summaryTableData.length > 0 &&
                                    <DisplayCsvDataTable
                                        tableHead={[]}
                                        tableData={summaryTableData}
                                        numberOfPages={1}
                                        numItemsPerPage={10} />}
                            </Box>
                        </Center>
                    </ScrollView>
                </SafeAreaView>
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        margin: 4,
    },
    cardStyle: { margin: 4 }
});