import { createAction } from "typesafe-actions";

export const setUsername = createAction('SET_USERNAME')<string>();

export const reset = createAction('RESET')<void>();

export const logout = createAction('LOGOUT')<void>();

export const forceLogout = createAction('FORCE_LOGOUT')<void>();

export const forceLogoutHandled = createAction('FORCE_LOGOUT_HANDLED')<void>();