import FetchError from "./errors";
import { IPaymentRequest, ICreateSessionResponse } from "../models";
import { customFetch } from "./shared";

export const createPaymentSession = async (paymentRequst: IPaymentRequest): Promise<ICreateSessionResponse | null> => {
  try {
    const response = await customFetch(`/payment/stripe/checkout`, {
      method: "POST",
      body: JSON.stringify(paymentRequst)
    })
    if (response.ok) {
      return response.json();
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};