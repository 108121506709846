import { IRsuTaxCalculatorInput } from "src/models";
import { formatCurrency } from "../formatUtils";

export const rsuTaxCalculator = (rsuTaxCalculator: IRsuTaxCalculatorInput | undefined) => {
    if (rsuTaxCalculator === undefined) {
        return [];
    }

    const { currency, sharePrice, numberOfSharesGranted, numberOfSharesVested, taxPercent } = rsuTaxCalculator;

    const vestedAmount = numberOfSharesVested * sharePrice;
    const taxAmount = vestedAmount * (taxPercent / 100);
    const netAmount = vestedAmount - taxAmount;
    const netAmountPerShare = netAmount / numberOfSharesVested;

    return {
        "summary": [
            ["Total vested amount", formatCurrency(vestedAmount, currency)],
            ["Tax amount", formatCurrency(taxAmount, currency)],
            ["Net amount", formatCurrency(netAmount, currency)],
            ["Net amount per share", formatCurrency(netAmountPerShare, currency)]
        ],
        "pieChartData": [
            {
                label: 'Tax',
                svg: { fill: '#18c983' },
                value: taxAmount,
                percent: ((taxAmount) / vestedAmount) * 100
            },
            {
                label: 'Proceeds',
                svg: { fill: '#db692c' },
                value: netAmount || 0,
                percent: ((netAmount || 0) / vestedAmount) * 100
            }
        ]
    }
}