import { IInflationCalculatorInput } from "src/models/InflationCalculatorInput";
import { formatCurrency } from "../formatUtils";

export const inflationCalculator = (input: IInflationCalculatorInput | undefined) => {
    if (input === undefined) {
        return [];
    }

    let { amount, inflationRate, currency, endDate } = input;

    if (typeof endDate === "string") {
        endDate = new Date(endDate);
    }

    if (amount === undefined || inflationRate === undefined || currency === undefined || endDate === undefined) {
        return [];
    }

    const adjustedAmount = calculatePurchasingPower(amount, endDate.getFullYear(), inflationRate / 100.0);

    const adjustedAmounts = calculatePurchasingPowerOverYears(amount, endDate.getFullYear(), inflationRate / 100.0);

    return {
        "summary": [
            ["Initial amount", formatCurrency(amount, currency)],
            ["Inflation Rate", `${inflationRate}%`],
            ["End Date", endDate.toLocaleDateString()],
            ["Adjusted Amount", formatCurrency(adjustedAmount, currency)]   
        ],
        "lineChartData": adjustedAmounts
    }
}

function calculatePurchasingPower(amount: number, endYear: number, inflationRate: number) {
    const currentYear = new Date().getFullYear();
    const yearsPassed = endYear - currentYear;
    const adjustedAmount = amount / Math.pow(1 + inflationRate, yearsPassed);
    return adjustedAmount;
}

function calculatePurchasingPowerOverYears(amount: number, endYear: number, inflationRate: number) {
    const currentYear = new Date().getFullYear();

    const adjustedAmounts = [];

    for (let i = currentYear; i <= endYear; i++) {
        const yearsPassed = i - currentYear;
        const adjustedAmount = amount / Math.pow(1 + inflationRate, yearsPassed);
        adjustedAmounts.push(adjustedAmount);
    }

    return adjustedAmounts;
}