import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";

import * as UploadAction from "../actions/uploadAction";
import * as JobAction from "../actions/jobAction";
import * as api from "../../api";
import { showErrorMessage } from "./shared";
import { IGetPresignedUrlRequest, IPresignedUrlRequest, ISignedUrlMapResponse, IUploadJobInputRequest, IUploadObjectRequest, IUploadRequest, KeyActionItem } from "../../models/UploadRequest";
import { IJobInputOutput, IJobRequest } from "src/models";

export function* putPresignedUrl(action: ReturnType<typeof UploadAction.putPresignedUrl.request>): any {
  try {
    const request: IUploadJobInputRequest = action.payload;

    const dataObjectMap = getDataObjectMap(request.jobId, request.dataObject);

    const presignedPutRequest: IPresignedUrlRequest = {
      keys: getKeyListFromDataObject("putObject", request.jobId, request.dataObject),
    }

    const response: ISignedUrlMapResponse = yield call(api.getPresignedUrl, presignedPutRequest);
    console.log('received put presigned url response')
    yield put(UploadAction.putPresignedUrl.success(response));

    for (var key in dataObjectMap) {

      const uploadResponse = yield call(api.uploadUsingPresignedUrl, {
        presignedUrl: response.signed_url_map[key],
        dataObject: dataObjectMap[key]
      });
      yield put(UploadAction.uploadUsingPresignedUrl.success(uploadResponse));

      console.log('uploadResponse', uploadResponse)
    }

    const presignedGetRequest: IPresignedUrlRequest = {
      keys: getKeyListFromDataObject("getObject", request.jobId, request.dataObject),
    }

    console.log('presignedGetRequest', presignedGetRequest)

    const getSignedUrlResponse: ISignedUrlMapResponse = yield call(api.getPresignedUrl, presignedGetRequest);
    console.log('received get presigned url response', getSignedUrlResponse)

    yield put(UploadAction.getPresignedUrl.success(getSignedUrlResponse));

    const createJobRequest: IJobRequest = {
      jobId: request.jobId,
      inputs: getInputMapFromSignedUrlMap(getSignedUrlResponse),
      username: request.username,
      model: request.model
    }

    console.log('queueJobRequest', createJobRequest)

    yield put(JobAction.createJob.request(createJobRequest));

  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.putPresignedUrl.failure(error));
  }
}

const getKeyListFromDataObject = (action: string, jobId: string, data: any) => {
  const keys: KeyActionItem[] = []
  for (let i = 0; i < data?.assets.length; i++) {
    keys.push({
      "action": action,
      "key": `input/${jobId}/images/${i}.png`,
    })
  }
  return keys;
}

const getDataObjectMap = (jobId: string, data: any) => {
  const dataObjectMap: any = {}
  for (let i = 0; i < data?.assets.length; i++) {
    dataObjectMap[`input/${jobId}/images/${i}.png`] = data?.assets[i];
  }
  return dataObjectMap;
}

const getInputMapFromSignedUrlMap = (signedUrlResp: ISignedUrlMapResponse): IJobInputOutput => {
  const inputMap: IJobInputOutput = {};
  inputMap['images'] = [];
  for (var key in signedUrlResp.signed_url_map) {
    inputMap['images'].push(signedUrlResp.signed_url_map[key]);
  }
  return inputMap;
}

export function* getPresignedUrl(action: ReturnType<typeof UploadAction.getPresignedUrl.request>): any {
  try {
    const request: IGetPresignedUrlRequest = action.payload;

    const presignedGetRequest: IPresignedUrlRequest = {
      keys: request.inputs
    }

    const response = yield call(api.getPresignedUrl, presignedGetRequest);
    console.log('received get presigned url response', response)
    yield put(UploadAction.getPresignedUrl.success(response));
  } catch (error: any) {
    showErrorMessage(error);
    yield put(UploadAction.getPresignedUrl.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchPutPresignedUrl() {
  yield takeEvery(UploadAction.putPresignedUrl.request, putPresignedUrl);
}

export function* watchGetPresignedUrl() {
  yield takeEvery(UploadAction.getPresignedUrl.request, getPresignedUrl);
}

export default function* root() {
  yield all([fork(watchPutPresignedUrl), fork(watchGetPresignedUrl)]);
}