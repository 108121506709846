import { all, fork } from "redux-saga/effects";

import job from "./job";
import upload from "./upload";
import notification from "./notification";
import payment from "./payment";
import user from "./user";

export function* rootSaga() {
  yield all([fork(job), fork(upload), fork(notification), fork(payment), fork(user)]);
}
