import { IAgeCalculatorInput, IDaysBetweeenTwoDatesCalculatorInput, IInvestmentCalculatorInput, IMortgageCalculatorInput, IRsuTaxCalculatorInput } from "src/models";
import { IInflationCalculatorInput } from "src/models/InflationCalculatorInput";
import { IRealEstateROICalculatorInput } from "src/models/RealEstateROICalculatorInput";
import { ITipCalculatorInput } from "src/models/TipCalculatorInput";
import { createAction } from "typesafe-actions";

export const reset = createAction('RESET')<void>();

export const setMortgageCalculatorInput = createAction('SET_MORTGAGE_CALCULATOR_INPUT')<IMortgageCalculatorInput>();

export const setTipCalculatorInput = createAction('SET_TIP_CALCULATOR_INPUT')<ITipCalculatorInput>();

export const setInflationCalculatorInput = createAction('SET_INFLATION_CALCULATOR_INPUT')<IInflationCalculatorInput>();

export const setRsuTaxCalculatorInput = createAction('SET_RSU_TAX_CALCULATOR_INPUT')<IRsuTaxCalculatorInput>();

export const setInvestmentCalculatorInput = createAction('SET_INVESTMENT_CALCULATOR_INPUT')<IInvestmentCalculatorInput>();

export const setRealEstateRoiCalculatorInput = createAction('SET_REAL_ESTATE_ROI_CALCULATOR_INPUT')<IRealEstateROICalculatorInput>();

export const setDaysBetweenTwoDatesCalculatorInput = createAction('SET_DAYS_BETWEEN_TWO_DATES_CALCULATOR_INPUT')<IDaysBetweeenTwoDatesCalculatorInput>();

export const setAgeCalculatorInput = createAction('SET_AGE_CALCULATOR_INPUT')<IAgeCalculatorInput>();