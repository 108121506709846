import FetchError from "./errors";
import { ITokenRequest } from "../models";
import { customFetch } from "./shared";

export const registerDeviceToken = async (tokenRequest: ITokenRequest): Promise<Boolean | null> => {
  try {
    const response = await customFetch(`/user/${tokenRequest.username}/device`, {
      method: "PUT",
      body: JSON.stringify(tokenRequest)
    })
    if (response.ok) {
      return true;
    }

    throw new FetchError(`Error fetching. ${await response.text()}`, response.status);
  } catch (e) {
    throw new FetchError(e, 500);
  }
};