import React from 'react'
import { PieChart } from 'react-native-svg-charts'
import { Text } from 'react-native-svg'
import { Center, Stack, Box, Text as NativeText } from 'native-base'

export class PieChartWithLabels extends React.PureComponent {


    render() {
        const Labels = ({ slices, height, width }) => {
            return slices.map((slice, index) => {
                const { labelCentroid, pieCentroid, data } = slice;
                return (
                    <Text
                        key={index}
                        x={pieCentroid[0]}
                        y={pieCentroid[1]}
                        fill={'black'}
                        textAnchor={'middle'}
                        alignmentBaseline={'middle'}
                        fontSize={10}
                    >
                        {`${data.percent.toFixed(1)} %`}
                    </Text>
                )
            })
        }

        return (
            <>
                <PieChart
                    animate={true}
                    style={{ height: 200 }}
                    valueAccessor={({ item }) => item.value}
                    data={this.props.data}
                    spacing={0}
                    outerRadius={'95%'}
                >
                    <Labels />
                </PieChart>
                <Center mt={4} mb={6}>
                    <Stack direction="row" space={2} alignItems="center" mt={2}>
                        {this.props.data.map((item, index) => {
                            return (
                                <Stack key={index} direction="row" space={2} alignItems="center">
                                    <Stack
                                        bg={item.svg.fill}
                                        style={{ width: 10, height: 10, borderRadius: 5 }}
                                    />
                                    <NativeText fontSize="md">{item.label}</NativeText>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Center>
            </>
        )
    }
}