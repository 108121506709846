import { IAgeCalculatorInput } from "src/models";
import { IDaysBetweeenTwoDatesCalculatorInput } from "src/models/DaysBetweeenTwoDatesCalculatorInput";

export const daysBetweenTwoDateCalculator = (daysInput: IDaysBetweeenTwoDatesCalculatorInput | undefined) => {
    if (daysInput === undefined) {
        return [];
    }

    let { startDate, endDate } = daysInput;

    if(typeof startDate === "string" || typeof endDate === "string") {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
    }
    
    const daysBetween = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const weeksBetween = Math.floor(daysBetween / 7);
    const monthsBetween = Math.floor(daysBetween / 30);
    const yearsBetween = Math.floor(daysBetween / 365);

    return {
        "summary": [
            ["Days", daysBetween],
            ["Weeks", weeksBetween],
            ["Months", monthsBetween],
            ["Years", yearsBetween],
        ]
    }
}

export const ageCalculator = (daysInput: IAgeCalculatorInput | undefined) => {
    if (daysInput === undefined) {
        return [];
    }

    let { startDate, endDate } = daysInput;

    if(typeof startDate === "string" || typeof endDate === "string") {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
    }
    
    const daysBetween = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const weeksBetween = Math.floor(daysBetween / 7);
    const monthsBetween = Math.floor(daysBetween / 30);
    const yearsBetween = Math.floor(daysBetween / 365);

    return {
        "summary": [
            ["Days", daysBetween],
            ["Weeks", weeksBetween],
            ["Months", monthsBetween],
            ["Years", yearsBetween],
        ]
    }
}