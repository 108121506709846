import React from 'react';
import { View, Box, Button, FormControl, Center, ScrollView } from 'native-base';
import { SafeAreaView, StyleSheet } from 'react-native';
import { allCurrencies } from '../../../../../data/currencyData';
import { useDispatch, useSelector } from 'react-redux';
import { setRealEstateRoiCalculatorInput } from '../../../../../redux/actions/calculatorAction';
import { IStore } from 'src/redux/reducers';
import AmountWithCurrencyInput from 'src/components/common/AmountWithCurrencyInput';
import ValueOrPercentInput from 'src/components/common/ValueOrPercentInput';
import PercentInput from 'src/components/common/PercentInput';
import ValueWithInputTypeToogle from 'src/components/common/ValueWithInputTypeToogle';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { IRealEstateROICalculatorInput } from 'src/models/RealEstateROICalculatorInput';

export default function RealEstateROICalculatorInput({ navigation }) {

    const dispatch = useDispatch();

    const calculatorInputs: IRealEstateROICalculatorInput | undefined = useSelector((store: IStore) => store.calculator.realEstateROICalculatorInput);

    const currencySymbolFromCode = allCurrencies.find(x => x.CurrencyCode === calculatorInputs?.currency)?.Symbol || "$";

    const [downPaymentType, setDownpaymentType] = React.useState(calculatorInputs?.downPaymentType || currencySymbolFromCode);
    const [currencyDisplay, setCurrencyDisplay] = React.useState(currencySymbolFromCode);
    const [currencyCode, setCurrencyCode] = React.useState(calculatorInputs?.currency || "USD");

    const [homePrice, setHomePrice] = React.useState(`${calculatorInputs?.purchasePrice || 1000000}`);
    const [downPayment, setDownPayment] = React.useState(`${calculatorInputs?.downPayment || 200000}`);

    const [closingCost, setClosingCost] = React.useState(`${calculatorInputs?.closingCosts || 0}`);

    const [loanTerm, setLoanTerm] = React.useState(`${calculatorInputs?.loanTerm || 30}`);
    const [interestRate, setInterestRate] = React.useState(`${calculatorInputs?.interestRate || 3.5}`);

    const [monthlyRent, setMonthlyRent] = React.useState(`${calculatorInputs?.monthlyRent || 5000}`);
    const [otherMonthlyIncome, setOtherMonthlyIncome] = React.useState(`${calculatorInputs?.otherMonthlyIncome || 0}`);
    const [vacancyRate, setVacancyRate] = React.useState(`${calculatorInputs?.vacancyRate || 5}`);
    const [annualRentIncrease, setAnnualRentIncrease] = React.useState(`${calculatorInputs?.annualRentIncreaseRate || 3}`);
    const [propertyManagementFees, setPropertyManagementFees] = React.useState(`${calculatorInputs?.propertyManagementFees || 0}`); // [0, 100

    const [propertyTax, setPropertyTax] = React.useState(`${calculatorInputs?.propertyTaxes || 0}`);
    const [insurance, setInsurance] = React.useState(`${calculatorInputs?.insurance || 0}`);
    const [hoa, setHoa] = React.useState(`${calculatorInputs?.hoaFees || 0}`);
    const [maintenance, setMaintenance] = React.useState(`${calculatorInputs?.repairAndMaintenance || 0}`);

    const [annualAppreciation, setAnnualAppreciation] = React.useState(`${calculatorInputs?.annualAppreciationRate || 3}`);
    const [holdingPeriod, setHoldingPeriod] = React.useState(`${calculatorInputs?.yearsBeforeSale || 5}`);
    const [sellingCost, setSellingCost] = React.useState(`${calculatorInputs?.sellingCosts || 6}`);

    const [loading, setLoading] = React.useState(false);


    const setCalculatorInputs = () => {
        setLoading(true);
        dispatch(setRealEstateRoiCalculatorInput({
            currency: currencyCode,
            purchasePrice: parseFloat(homePrice),
            downPayment: parseFloat(downPayment),
            downPaymentType: downPaymentType,
            closingCosts: parseFloat(closingCost),
            loanTerm: parseFloat(loanTerm),
            interestRate: parseFloat(interestRate),
            monthlyRent: parseFloat(monthlyRent),
            otherMonthlyIncome: parseFloat(otherMonthlyIncome),
            vacancyRate: parseFloat(vacancyRate),
            annualRentIncreaseRate: parseFloat(annualRentIncrease),
            propertyTaxes: parseFloat(propertyTax),
            insurance: parseFloat(insurance),
            hoaFees: parseFloat(hoa),
            repairAndMaintenance: parseFloat(maintenance),
            annualAppreciationRate: parseFloat(annualAppreciation),
            yearsBeforeSale: parseFloat(holdingPeriod),
            sellingCosts: parseFloat(sellingCost),
            propertyManagementFees: parseFloat(propertyManagementFees),
        }));

        setLoading(false);
        navigation.jumpTo('Real Estate ROI Summary', {});
    }

    return (
        <>
            <ScrollView>
                <View style={styles.container}>
                    <SafeAreaView style={styles.listContainer}>
                        <KeyboardAwareScrollView>
                            <Center>
                                <Box w="95%">
                                    <FormControl>
                                        <FormControl.Label>Purchase</FormControl.Label>
                                        <AmountWithCurrencyInput label={"Purchase Price"}
                                            initialAmount={homePrice}
                                            onAmountChange={(newAmount: string) => {
                                                setHomePrice(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"Closing Costs"}
                                            initialAmount={closingCost}
                                            onAmountChange={(newAmount: string) => {
                                                setClosingCost(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <ValueOrPercentInput
                                            initialValue={downPayment}
                                            maxAmount={homePrice}
                                            label={"Down Payment"}
                                            nonPercentType={"currency"}
                                            currency={currencyCode}
                                            inputType={downPaymentType}
                                            sliderVisible={true}
                                            sliderStepSize={10000}
                                            onValueChange={(newValue: string, valueType: string) => {
                                                setDownPayment(newValue);
                                                setDownpaymentType(valueType);
                                            }} />

                                        <ValueWithInputTypeToogle
                                            label={"Loan Term"}
                                            sliderVisible={true}
                                            inputType={"yrs"}
                                            initialValue={loanTerm}
                                            onValueChange={(newValue: string, valueType: string) => {
                                                setLoanTerm(newValue);
                                            }}
                                            allTypes={
                                                [
                                                    { "type": "yrs", "label": "years", multiplier: 1, "min": 0, "max": 30 }
                                                ]
                                            }
                                        />
                                        <PercentInput label={"Interest Rate"}
                                            initialValue={interestRate}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setInterestRate(newValue);
                                            }} />

                                        <FormControl.Label>Income</FormControl.Label>

                                        <AmountWithCurrencyInput label={"Monthly Rent"}
                                            initialAmount={monthlyRent}
                                            onAmountChange={(newAmount: string) => {
                                                setMonthlyRent(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"Other Monthly Income"}
                                            initialAmount={otherMonthlyIncome}
                                            onAmountChange={(newAmount: string) => {
                                                setOtherMonthlyIncome(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"Other Monthly Income"}
                                            initialAmount={otherMonthlyIncome}
                                            onAmountChange={(newAmount: string) => {
                                                setOtherMonthlyIncome(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <PercentInput label={"Vacancy Rate"}
                                            initialValue={vacancyRate}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setVacancyRate(newValue);
                                            }} />
                                        <PercentInput label={"Annual Rent Increase"}
                                            initialValue={annualRentIncrease}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setAnnualRentIncrease(newValue);
                                            }} />
                                        <PercentInput label={"Property Management Fees"}
                                            initialValue={propertyManagementFees}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setPropertyManagementFees(newValue);
                                            }} />

                                        <FormControl.Label>Expenses</FormControl.Label>

                                        <AmountWithCurrencyInput label={"Property Tax"}
                                            initialAmount={propertyTax}
                                            onAmountChange={(newAmount: string) => {
                                                setPropertyTax(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"Insurance"}
                                            initialAmount={insurance}
                                            onAmountChange={(newAmount: string) => {
                                                setInsurance(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"HOA"}
                                            initialAmount={hoa}
                                            onAmountChange={(newAmount: string) => {
                                                setHoa(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />
                                        <AmountWithCurrencyInput label={"Maintenance"}
                                            initialAmount={maintenance}
                                            onAmountChange={(newAmount: string) => {
                                                setMaintenance(newAmount)
                                            }}
                                            initialCurrency={currencyCode}
                                            onCurrencyChange={(newCurrency: any) => {
                                                setCurrencyDisplay(newCurrency.Symbol);
                                                setCurrencyCode(newCurrency.CurrencyCode);
                                                setDownpaymentType(newCurrency.Symbol);
                                            }} />

                                        <FormControl.Label>Appreciation</FormControl.Label>

                                        <PercentInput label={"Annual Appreciation"}
                                            initialValue={annualAppreciation}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setAnnualAppreciation(newValue);
                                            }
                                            } />
                                        <ValueWithInputTypeToogle
                                            label={"Holding Period"}
                                            sliderVisible={true}
                                            inputType={"yrs"}
                                            initialValue={holdingPeriod}
                                            onValueChange={(newValue: string, valueType: string) => {
                                                setHoldingPeriod(newValue);
                                            }
                                            }
                                            allTypes={
                                                [
                                                    { "type": "yrs", "label": "years", multiplier: 1, "min": 0, "max": 30 }
                                                ]
                                            }
                                        />
                                        <PercentInput label={"Selling Costs"}
                                            initialValue={sellingCost}
                                            sliderVisible={true}
                                            onValueChange={(newValue: string) => {
                                                setSellingCost(newValue);
                                            }
                                            } />
                                    </FormControl>

                                    <Button
                                        style={styles.inputStyle}
                                        my={4}
                                        shadow={2}
                                        isLoading={loading}
                                        isLoadingText='Calculating...'
                                        onPress={() => {
                                            setCalculatorInputs();
                                        }}>Calculate</Button>
                                </Box>
                            </Center>
                        </KeyboardAwareScrollView>
                    </SafeAreaView>
                </View>
            </ScrollView>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    listContainer: {
        marginTop: 8,
    },
    title: {
        fontSize: 12,
    },
    subTitle: {
        fontSize: 12,
    },
    badgeStyle: {
        margin: 4,
        color: '#000'
    },
    timeAgoStyle: {
        marginBottom: 4,
    },
    inputStyle: {
        marginTop: 24,
    },
    cardStyle: { margin: 4 }
});