export const months = [
    {
        label: 'January',
        shortName: 'Jan',
        value: "1"
    },
    {
        label: 'February',
        shortName: 'Feb',
        value: "2"
    },
    {
        label: 'March',
        shortName: 'Mar',
        value: "3"
    },
    {
        label: 'April',
        shortName: 'Apr',
        value: "4"
    },
    {
        label: 'May',
        shortName: 'May',
        value: "5"
    },
    {
        label: 'June',
        shortName: 'Jun',
        value: "6"
    },
    {
        label: 'July',
        shortName: 'Jul',
        value: "7"
    },
    {
        label: 'August',
        shortName: 'Aug',
        value: "8"
    },
    {
        label: 'September',
        shortName: 'Sep',
        value: "9"
    },
    {
        label: 'October',
        shortName: 'Oct',
        value: "10"
    },
    {
        label: 'November',
        shortName: 'Nov',
        value: "11"
    },
    {
        label: 'December',
        shortName: 'Dec',
        value: "12"
    }
]