import { ActionType, getType } from "typesafe-actions";
import * as actions from "../actions/calculatorAction";
import { IAgeCalculatorInput, IDaysBetweeenTwoDatesCalculatorInput, IInvestmentCalculatorInput, IMortgageCalculatorInput, IRsuTaxCalculatorInput } from "src/models";
import { ITipCalculatorInput } from "src/models/TipCalculatorInput";
import { IRealEstateROICalculatorInput } from "src/models/RealEstateROICalculatorInput";
import { IInflationCalculatorInput } from "src/models/InflationCalculatorInput";

export interface ICalculatorActionState {
    mortgagecalculatorInput: IMortgageCalculatorInput | undefined;
    tipCalculatorInput: ITipCalculatorInput | undefined;
    inflationCalculatorInput: IInflationCalculatorInput | undefined;
    rsuTaxCalculatorInput: IRsuTaxCalculatorInput | undefined;
    investmentCalculatorInput: IInvestmentCalculatorInput | undefined;
    realEstateROICalculatorInput: IRealEstateROICalculatorInput | undefined;
    daysBetweenTwoDatesCalculatorInput: IDaysBetweeenTwoDatesCalculatorInput | undefined;
    ageCalculatorInput: IAgeCalculatorInput | undefined;
}

export const initialCalculatorState: ICalculatorActionState = {
    mortgagecalculatorInput: undefined,
    tipCalculatorInput: undefined,
    inflationCalculatorInput: undefined,
    rsuTaxCalculatorInput: undefined,
    investmentCalculatorInput: undefined,
    realEstateROICalculatorInput: undefined,
    daysBetweenTwoDatesCalculatorInput: undefined,
    ageCalculatorInput: undefined
};

export const CalculatorReducer = (
    state: ICalculatorActionState = initialCalculatorState,
    action: ActionType<typeof actions>
): ICalculatorActionState => {
    switch (action.type) {
        case getType(actions.setMortgageCalculatorInput):
            return {
                ...state,
                mortgagecalculatorInput: action.payload
            };
        case getType(actions.setTipCalculatorInput):
            return {
                ...state,
                tipCalculatorInput: action.payload
            };
        case getType(actions.setInflationCalculatorInput):
            return {
                ...state,
                inflationCalculatorInput: action.payload
            };
        case getType(actions.setRsuTaxCalculatorInput):
            return {
                ...state,
                rsuTaxCalculatorInput: action.payload
            };
        case getType(actions.setInvestmentCalculatorInput):
            return {
                ...state,
                investmentCalculatorInput: action.payload
            };
        case getType(actions.setRealEstateRoiCalculatorInput):
            return {
                ...state,
                realEstateROICalculatorInput: action.payload
            };
        case getType(actions.setDaysBetweenTwoDatesCalculatorInput):
            return {
                ...state,
                daysBetweenTwoDatesCalculatorInput: action.payload
            };
        case getType(actions.setAgeCalculatorInput):
            return {
                ...state,
                ageCalculatorInput: action.payload
            };
        case getType(actions.reset):
            return initialCalculatorState;
        default:
            return state;
    }
};