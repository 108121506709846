import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import { calculateRealEstateROI } from 'src/utils/finance/realEstateROIUtils';
import { IRealEstateROICalculatorInput } from 'src/models/RealEstateROICalculatorInput';
import SummaryView from 'src/components/common/SummaryView';

export default function RealEstateRoiCalculatorSummary() {

    const calculatorInputs: IRealEstateROICalculatorInput | undefined = useSelector((store: IStore) => store.calculator.realEstateROICalculatorInput);

    const [result, setResult] = React.useState<any>({});

    React.useEffect(() => {
        const result = calculateRealEstateROI(calculatorInputs);
        setResult(result || []);
    }, [calculatorInputs]);

    return <SummaryView summary={result} />;
};