import { combineReducers } from 'redux';
import { StateType } from "typesafe-actions";

import { JobReducer } from './jobReducer'
import { CalculatorReducer } from './calculatorReducer';
import { UploadReducer } from './uploadReducer'
import { AppReducer } from './appReducer'
import { NotificationReducer } from './notification'
import { PaymentReducer } from './paymentReducer'
import { UserReducer } from './userReducer'

import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';

const rootReducer = combineReducers({
  job: JobReducer,
  upload: UploadReducer,
  app: AppReducer,
  calculator: CalculatorReducer,
  notification: NotificationReducer,
  payment: PaymentReducer,
  user: UserReducer,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['notification', 'user', 'calculator']
};

export const pReducer = persistReducer(persistConfig, rootReducer);

export * from "./jobReducer";
export * from "./uploadReducer";
export * from "./appReducer";
export * from "./notification";
export * from "./paymentReducer";
export * from "./userReducer";
export * from "./calculatorReducer";

export type IStore = StateType<typeof pReducer>;