import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'native-base';
import csv from 'csvtojson';
import { DataTable } from 'react-native-paper';

export default function DisplayCsvDataTable(props) {

  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState(10);
  const [showTableHead, setShowTableHead] = React.useState(true);
  const [showPagination, setShowPagination] = React.useState(true);

  const [state, setState] = React.useState({
    tableHead: [],
    tableData: [
      []
    ],
    currentPageData: [
      []
    ],
    numberOfPages: 1
  });

  useEffect(() => {
    if (props.csvFileUrl != null && props.csvFileUrl != undefined && props.csvFileUrl != "") {
      setTableData(props.csvFileUrl);
    } else {
      setTableDataUsingProps()
    }

    setNumberOfItemsPerPage(props.numItemsPerPage);
  }, []);

  useEffect(() => {
    setTableDataUsingProps()
  }, [props.tableData]);

  useEffect(() => {
    setCurrentPageData();
  }, [page]);

  useEffect(() => {
    setCurrentPageData();
  }, [state.tableData]);

  const setTableDataUsingProps = () => {
    setState({
      ...state,
      tableHead: props.tableHead,
      tableData: props.tableData,
      numberOfPages: props.numberOfPages
    });

    if (props.numberOfPages <= 1) {
      setShowPagination(false);
    }

    if (props.tableHead.length <= 0) {
      setShowTableHead(false);
    }
  }

  const setCurrentPageData = () => {
    const startIndex = page * numberOfItemsPerPage;
    let endIndex = startIndex + numberOfItemsPerPage;
    if (endIndex > state.tableData.length) {
      endIndex = state.tableData.length;
    }

    if (state.tableData.length > 1) {
      setState({
        ...state,
        currentPageData: state.tableData.slice(startIndex, endIndex)
      });
    }
  }

  const setTableData = (csvFileUrl) => {
    fetch(csvFileUrl)
      .then(async (response) => {
        const resp = await response.text();
        csv({
          noheader: true,
          output: "csv"
        }).fromString(resp)
          .then((csvRow) => {
            let pages = (csvRow.length / numberOfItemsPerPage);
            if (csvRow.length > numberOfItemsPerPage * pages) {
              pages = pages + 1;
            }
            setState({
              ...state,
              tableHead: csvRow[0],
              tableData: csvRow.slice(1),
              numberOfPages: pages
            });
          })
      })
      .catch((error) => {
        console.error("some error occurred", error);
      });
  }

  return (
    <View style={styles.container}>
      <DataTable>
        {
          showTableHead &&
          <DataTable.Header>
            {
              state.tableHead.map((rowData, index) => (
                <DataTable.Title key={index}>
                  <Text style={styles.text}>{rowData}</Text>
                </DataTable.Title>
              ))
            }
          </DataTable.Header>
        }

        {
          state.currentPageData.map((rowData, index) => (
            <DataTable.Row key={index}>
              {
                rowData.map((cellData, cellIndex) => (
                  <DataTable.Cell key={cellIndex}>
                    <Text style={{
                      ...styles.text,
                      color: cellIndex % 2 == 0 ? 'grey' : '#000',
                      fontSize: state.currentPageData[0].length > 2 ? 10 : 20,
                    }}>{cellData}</Text>
                  </DataTable.Cell>
                ))
              }
            </DataTable.Row>
          ))
        }

        {
          state.numberOfPages > 1 &&
          <DataTable.Pagination
            page={page}
            style={{
              backgroundColor: 'grey',
              color: '#fff'
            }}
            numberOfPages={state.numberOfPages}
            onPageChange={(page) => setPage(page)}
            label={`Page ${page + 1} of ${state.numberOfPages}`}
            showFastPagination
            optionsLabel={'Rows per page'}
          />
        }
      </DataTable>
    </View>
  );
};
const styles = StyleSheet.create({
  container: { flex: 1, padding: 4, paddingTop: 4 },
  head: {
    height: 40, backgroundColor: '#f1f8ff', color: '#fff'
  },
  text: {
    margin: 4,
    fontSize: 12,
    color: '#000'
  },
});