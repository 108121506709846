import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { enableScreens } from 'react-native-screens';

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import InfoPage from './pages/InfoPage';
import { store, persistedStore } from './redux/store';
import { Provider as PaperProvider } from 'react-native-paper';
import { ToastProvider } from 'react-native-toast-notifications'
// import { Authenticator } from 'aws-amplify-react-native'
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";


import { Box, HStack, Icon, NativeBaseProvider, StatusBar, Text, IconButton } from "native-base";

import { StyleSheet, Platform } from "react-native";

// import Amplify from '@aws-amplify/core'
import { Auth as Authorization } from 'aws-amplify';
import { APP_THEME, USER_POOL_ID, USER_POOL_REGION, USER_POOL_WEB_CLIENT_ID } from './constants'
import RNBootSplash from 'react-native-bootsplash';

import { allTools } from './data/tools';

Authorization.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: USER_POOL_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: USER_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: 'mdh-app.auth.us-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'http://localhost:8080/callback/auth',
      redirectSignOut: 'http://localhost:8080',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  Analytics: {
    disabled: true,
  },
});

// Optimize React Navigation: https://reactnavigation.org/docs/react-native-screens/
enableScreens();

const Stack = createNativeStackNavigator();


function CustomNavigationBar({ navigation, options }) {

  return (
    <>
      <StatusBar barStyle="light-content" />
      <Box safeAreaTop bg="gray.600" />
      <HStack bg="gray.800" px="1" py="3"
        justifyContent="space-between"
        alignItems="center"
        w="100%">
        <HStack alignItems="center">
          {
            options.previous &&
            <IconButton
              icon={<Icon size="lg" as={MaterialCommunityIcons}
                name="arrow-left"
                onPress={() => {
                  navigation.navigate("Home");
                }}
                color="white" />} />
          }
          <Text color="white"
            width={"100%"}
            fontSize="20"
            px={4}>
            {options.title === undefined ? "Free Calculator" : options.title}
          </Text>
        </HStack>
      </HStack>
    </>
  );
}

const App = () => {

  const tools = allTools.map((category) => {
    return category.tools;
  }).flat();

  const config = {
    screens: {
      Home: {
        path: 'home',
      },
    },
  };

  allTools.forEach((category) => {
    config.screens[category.category] = {
      screens: {},
      path: category.category,
    }
    category.tools.forEach((tool) => {
      config.screens[category.category].screens[tool.name] = {
        path: tool.name,
      }
    })
  });

  const linking = {
    prefixes: ['https://mdh-app.com', 'mdh-app://', 'http://localhost:8080'],
    config,
  };

  return (
    <ToastProvider>
      <NativeBaseProvider>
        <PaperProvider>
          <React.Fragment>
            {Platform.OS === 'web' ? (
              <style type="text/css">{`
          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
          }
        `}</style>
            ) : null}
            <NavigationContainer linking={linking}>
              <Stack.Navigator
                screenOptions={{
                  header: (props) => <CustomNavigationBar {...props} />,
                }}>

                <Stack.Screen
                  name="Home"
                  component={InfoPage}
                  options={{
                    title: 'Free Calculator',
                    showTitle: true,
                    settings: false
                  }} />
                {
                  tools.map((tool, index) => {
                    return <Stack.Screen key={index}
                      name={`${tool.category}\/${tool.name}`}
                      component={tool.component}
                      options={{
                        title: tool.displayName,
                        showTitle: true,
                        previous: true,
                        settings: false
                      }} />
                  })
                }
              </Stack.Navigator>
            </NavigationContainer>
          </React.Fragment>

        </PaperProvider>
      </NativeBaseProvider>
    </ToastProvider>

  );
}

const styles = StyleSheet.create({
  appbar: {
    backgroundColor: APP_THEME.primaryColor,
  },
  appbarTitle: {
    textAlign: 'center',
  },
  grainTitle: {
    textAlign: 'center',
    fontSize: 14
  }
});

export const Root = () => {
  const [authState, setAuthState] = React.useState('');

  useEffect(() => {
    console.log('platform', Platform.OS)
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      RNBootSplash.hide({});
    }
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <App />
      </PersistGate>
    </Provider>
  )
};

// export default Root;